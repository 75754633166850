/* eslint-disable no-unused-vars */
import React, { Suspense, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  OrbitControls,
  PerspectiveCamera,
  Html,
  useProgress,
} from "@react-three/drei";
import Avatar from "./Avatar";
import * as THREE from "three";
import { DoubleSide } from "three";
// import GrassPlane from "./GrassPlane";
import useEventListener from "@use-it/event-listener";
import { Vector3 } from "three";
import GrassPlane from "./GrassPlane";

const keyPressed = {};

function Loader3D() {
  const { progress } = useProgress();
  return (
    <Html>
      <div
        style={{
          // display: "flex",
          // alignItem: "center",
          // justifyContent: "center",
          // flexDirection: "column",
          width: "90vw",
          color: "#fff",
        }}
      >
        <div className="loader-ripple ml-5">
          <div></div>
          <div></div>
        </div>
        <div style={{ width: "100%" }}>
          {progress.toFixed(2)}% Loading 3D View
        </div>
      </div>
    </Html>
  );
}

export default function Scene({ canvasProps, avatarProps, ...props }) {
  React.useEffect(() => {}, []);
  return (
    <Canvas
      //   camera={{ position: [2, 0, 12.25], fov: 18 }}
      style={{ width: "100vw", height: "100vh" }}
      {...canvasProps}
    >
      <ambientLight intensity={1} />
      <directionalLight position={[0, 1000, 0]} intensity={1} color={"white"} />
      <Suspense fallback={<Loader3D />}>
        <Avatar position={[0.025, -0.9, 4]} {...avatarProps} />
        <GreenPlane />
      </Suspense>
      <PerspectiveCamera position={[0, 1, 4.5]} makeDefault />
      <OrbitControls
        enablePan={false}
        enableRotate={false}
        enableZoom={false}
        enableDamping={false}
      />
    </Canvas>
  );
}

function Camara() {
  const { camera, mouse } = useThree();

  const lightTargetYDelta = 120;
  const lightTargetXDelta = 80;
  const [lightPosition, setLightPosition] = useState([
    -lightTargetXDelta,
    -lightTargetYDelta,
    200,
  ]);
  const [lightTargetPosition, setLightTargetPosition] = useState([0, 0, 0]);

  const handleKeyDown = (e) => {
    if (!keyPressed[e.key]) {
      keyPressed[e.key] = new Date().getTime();
    }
  };

  const handleKeyUp = (e) => {
    delete keyPressed[e.key];
  };

  const onCameraMoved = (delta) => {
    const newLightPosition = delta.map((e, idx) => lightPosition[idx] + e);
    setLightPosition(newLightPosition);
    const newLightTargetPosition = [
      newLightPosition[0] + lightTargetXDelta,
      newLightPosition[1] + lightTargetYDelta,
      0,
    ];
    setLightTargetPosition(newLightTargetPosition);
  };

  // move camera according to key pressed
  const updateCameraPositon = (delta) => {
    // if no key pressed, no update required
    if (Object.entries(keyPressed).length === 0) {
      return;
    }
    const newCameraPosition = new Vector3();
    newCameraPosition.copy(camera.position);
    Object.entries(keyPressed).forEach((e) => {
      const [key, start] = e;
      const duration = new Date().getTime() - start;

      // increase momentum if key pressed longer
      let momentum = Math.sqrt(duration + 200) * 0.01 + 0.05;

      // adjust for actual time passed
      momentum = (momentum * delta) / 0.016;

      // increase momentum if camera higher
      momentum = momentum + camera.position.z * 0.02;

      switch (key) {
        case "w":
          newCameraPosition.set(
            newCameraPosition.x,
            newCameraPosition.y + momentum,
            newCameraPosition.z
          );
          break;
        case "s":
          newCameraPosition.set(
            newCameraPosition.x,
            newCameraPosition.y - momentum,
            newCameraPosition.z
          );
          break;
        case "d":
          newCameraPosition.set(
            newCameraPosition.x + momentum,
            newCameraPosition.y,
            newCameraPosition.z
          );
          break;
        case "a":
          newCameraPosition.set(
            newCameraPosition.x - momentum,
            newCameraPosition.y,
            newCameraPosition.z
          );
          break;
        default:
      }
    });
    const cameraDelta = camera.position
      .toArray()
      .map((e, idx) => newCameraPosition.toArray()[idx] - e);
    onCameraMoved(cameraDelta);
    camera.position.copy(newCameraPosition);
  };

  useFrame((_, delta) => {
    updateCameraPositon(delta);
  });

  useEventListener("keydown", handleKeyDown);
  useEventListener("keyup", handleKeyUp);
  return <PerspectiveCamera position={[0, 1, 4.5]} makeDefault />;
}

function GreenPlane() {
  useFrame((state) => {
    // console.log("state", head.current);
    state.camera.position.x = THREE.MathUtils.lerp(
      state.camera.position.x,
      1 + state.mouse.x / 4,
      0.075
    );
    state.camera.position.y = THREE.MathUtils.lerp(
      state.camera.position.y,
      1.5 + state.mouse.y / 4,
      0.075
    );
  });
  return true ? (
    <GrassPlane />
  ) : (
    // The mesh is at the origin
    // Since it is inside a group, it is at the origin
    // of that group
    // It's rotated by 90 degrees along the X-axis
    // This is because, by default, planes are rendered
    // in the X-Y plane, where Y is the up direction
    <mesh
      position={[0, -0.038, 0]}
      rotation={[Math.PI / 2, 0, 0]}
      scale={[2, 2, 2]}
      // geometry={nodes["10438_Circular_Grass_Patch_v1_iterations-2001"].geometry}
      // material={materials["10438_Circular_Grass_Patch_v1"]}
    >
      <planeBufferGeometry attach="geometry" args={[1, 1]} />
      <meshBasicMaterial
        color="#A1DF50"
        attach={"material"}
        side={DoubleSide}
      />
    </mesh>
  );
}
