/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { enableAvatarView } from "../../config";
import { API_URL } from "../../config/env";

const path = `${API_URL}/v1/avatar/grass-plane.glb`;

export default function GrassPlane({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF(path);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={
          nodes["10450_Rectangular_Grass_Patch_v1_iterations-2"].geometry
        }
        material={materials["10450_Rectangular_Grass_Patch_v1"]}
        position={[0, -0.07, 0]}
        // rotation={[0.05, -0.1, 0]}
        scale={0.007}
      />
      <planeBufferGeometry attach="geometry" args={[1, 1]} />
    </group>
  );
}

if (!!enableAvatarView) {
  useGLTF.preload(path);
}
