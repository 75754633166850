import Home from './Pages/home';
import About from "./Pages/about";
// import Skill from './Pages/skill';
import Contact from "./Pages/contact";
import work from "./Pages/work";
import SkillV2 from "./Pages/skill-v2/index";
import ProjectDetails from "./Pages/project-details";
import Experience from "./Pages/experience";
import Resume from "./Pages/resume";
import Resume2 from "./Pages/resume-v2";

const routes = [
  {
    path: "/",
    name: "About Me",
    // component: About,
    component: Home,
    exact: true,
    icon: "fa-home",
    isActive: true,
    showOnNavbar: true,
  },
  {
    path: "/about",
    name: "About Me",
    component: About,
    exact: true,
    icon: "fa-user",
    isActive: false,
    showOnNavbar: false,
  },
  {
    path: "/skill",
    name: "Skills",
    component: SkillV2,
    exact: true,
    icon: "fa-pencil-ruler",
    isActive: true,
    showOnNavbar: true,
  },
  {
    path: "/experience",
    name: "Experience",
    component: Experience,
    exact: true,
    icon: "fa-briefcase",
    isActive: true,
    showOnNavbar: true,
  },
  {
    path: "/project",
    name: "Projects",
    component: work,
    exact: true,
    icon: "fa-code",
    isActive: true,
    showOnNavbar: true,
  },
  {
    path: "/resume",
    name: "Resume",
    component: Resume,
    exact: true,
    icon: "fa-clipboard",
    isActive: false && window.innerWidth > 600 && false,
    showOnNavbar: false && window.innerWidth > 600 && false,
  },
  {
    path: "/resume",
    name: "Reusme",
    component: Resume2,
    exact: true,
    icon: "fa-clipboard",
    isActive: window.innerWidth > 600 && true,
    showOnNavbar: window.innerWidth > 600 && true,
  },
  {
    path: "/contact",
    name: "Contact Me",
    component: Contact,
    exact: true,
    icon: "fa-envelope",
    isActive: true,
    showOnNavbar: true,
  },
  {
    path: "/project/:projectId",
    name: "Project Detail",
    component: ProjectDetails,
    exact: true,
    icon: "fa-code",
    isActive: true,
    showOnNavbar: false,
  },
];

export default routes;
