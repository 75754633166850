import React from 'react';


const Gallery = ({ gallery }) => {
  return (
    <div className="Gallery">
      <div className="carousel-container position-relative row">
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            {
              gallery.length > 0
                ? gallery.map((img, index) => (
                  <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} data-slide-number={index}>
                    <img src={img.ImageUrl} className="d-block w-100" alt={img.ImageName} data-type="image" data-toggle="lightbox" data-gallery="example-gallery" />
                  </div>
                ))
                : null
            }

          </div>
        </div>
        <div id="carousel-thumbs" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row mx-0">
                {
                  gallery.length > 0
                    ? gallery.map((img, index) => (
                      <div key={index} id={`carousel-selector-${index}`} className="thumb col-4 col-sm-2 px-1 py-2" data-target="#myCarousel" data-slide-to={index}>
                        <img src={img.ImageUrl} className="img-fluid" alt={img.ImageName} />
                      </div>
                    ))
                    : null
                }

              </div>
            </div>
            {/* <a className="carousel-control-prev" href="#carousel-thumbs" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carousel-thumbs" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                  </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery;