import React, { Component } from 'react';
import './header.css';
import { withRouter, Link } from 'react-router-dom';
import routes from '../../routes';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true
    }
  }
  componentDidMount() {
    setTimeout(() => this.setState({ loader: false }), 3000);
  }
  render() {
    return (
      <aside id="sidebar">
        <Link to="/" className="brand-thumb">
          <span className="logo">AS</span>
        </Link>
        <nav id="navigation">
          <ul className="nav-block">
            {
              routes.filter(link => link.showOnNavbar).map((link, i) => {
                const path = link.path.split('/')[1]
                let isMatch = ((this.props.location.pathname === '/' && link.path === '/') ? true : (link.path === '/') ? false : this.props.location.pathname.includes(path));
                return <li key={i} className="navigation-item">
                  <Link to={link.path} data-toggle="tooltip" data-placement="right" title={link.name} className={`navigation-item--link ${isMatch ? 'active' : ''}`}>
                    <i className={`fas ${link.icon} fa-lg`} />
                  </Link>
                </li>
              })
            }
          </ul>
        </nav>
        <ul className="ex-nav">
          <li className="ex-nav--item">
            <a target="_blank" className="d-block resume" rel="noopener noreferrer" href="/resume.pdf"> <i className={`fa fa-download`} /> </a>
          </li>
          <li className="ex-nav--item">
            <a target="_blank" className="d-block linkedin" rel="noopener noreferrer" href="https://www.linkedin.com/in/sanghvi-ankur"> <i className="fab fa-linkedin-in"></i></a>
          </li>
          <li className="ex-nav--item">
            <a target="_blank" className="d-block github" rel="noopener noreferrer" href="https://github.com/techbot11"> <i className="fab fa-github"></i></a>
          </li>
          <li className="ex-nav--item">
            <a target="_blank" className="d-block twitter" rel="noopener noreferrer" href="https://twitter.com/sanghvi_ankur"> <i className="fab fa-twitter"></i> </a>
          </li>
          {/* <li className="ex-nav--item"><a href="javascript:void(0);" className="settings d-block"><svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path className="animate-duration-2s animate-rotate" d="M17.344 9L19 9C19.552 9 20 9.448 20 10C20 10.552 19.552 11 19 11L17.344 11C17.103 11 16.934 11.682 16.857 11.91C16.588 12.714 16.327 13.122 15.846 13.852C15.713 14.053 15.73 14.316 15.9 14.486L17.071 15.657C17.462 16.047 17.462 16.681 17.071 17.071C16.681 17.462 16.047 17.462 15.657 17.071L14.486 15.9C14.316 15.73 14.053 15.713 13.852 15.846C13.122 16.327 12.297 16.676 11.41 16.857C11.174 16.906 11 17.103 11 17.344L11 19C11 19.552 10.552 20 10 20C9.448 20 9 19.552 9 19L9 17.344C9 17.103 8.826 16.904 8.59 16.855C7.703 16.674 6.878 16.323 6.148 15.842C5.947 15.709 5.684 15.722 5.514 15.892L4.343 17.055C3.953 17.446 3.319 17.43 2.929 17.04C2.538 16.649 2.538 15.985 2.929 15.594L4.1 14.361C4.27 14.191 4.287 13.803 4.154 13.602C3.673 12.872 3.418 12.21 3.237 11.323C3.189 11.088 2.897 11 2.656 11L1 11C0.448 11 0 10.552 0 10C0 9.448 0.448 9 1 9L2.656 9C2.70479 9 2.7551 9.00324 2.80453 9.00642C2.99927 9.01895 3.18047 9.03061 3.202 8.84C3.344 7.562 3.66 6.831 4.154 6.148C4.296 5.953 4.27 5.684 4.1 5.514L2.929 4.343C2.538 3.953 2.538 3.319 2.929 2.929C3.319 2.538 3.953 2.538 4.343 2.929L5.514 4.1C5.684 4.27 5.947 4.287 6.148 4.154C6.878 3.672 7.703 3.324 8.59 3.143C8.826 3.094 9 2.897 9 2.656L9 1C9 0.448 9.448 0 10 0C10.552 0 11 0.448 11 1L11 2.656C11 2.897 11.174 3.092 11.41 3.141C12.297 3.322 13.122 3.669 13.852 4.151C14.053 4.283 14.316 4.262 14.486 4.092L15.657 2.913C16.047 2.523 16.681 2.507 17.071 2.898C17.462 3.288 17.462 3.89 17.071 4.281L15.9 5.389C15.73 5.559 15.682 5.721 15.846 5.898C16.488 6.59 16.779 7.854 16.774 8.743C16.772 8.984 17.103 9 17.344 9ZM14.82 11.358C14.377 13.027 13.027 14.377 11.358 14.82C7.55796 15.828 4.17196 12.442 5.17996 8.642C5.62296 6.973 6.97296 5.623 8.64196 5.18C12.442 4.172 15.828 7.558 14.82 11.358ZM10 11C10.552 11 11 10.552 11 10C11 9.448 10.552 9 10 9C9.448 9 9 9.448 9 10C9 10.552 9.448 11 10 11Z" fill="#FFFFFF" fillRule="evenodd" stroke="none" /></svg></a></li>
          <li className="ex-nav--item dropright"><a href="javascript:void(0);" className="d-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div className="avatar avatar-xs d-inline-block"><img src="../../../assets/images/user/thumb/1.jpg" alt="" /></div>
          </a>
            <div className="dropdown-menu pt-0 overflow-hidden">
              <div className="pt-4 px-4 mb-3 text-center">
                <div className="avatar avatar-lg mx-auto mb-2"><img src="../../../assets/images/user/thumb/1.jpg" alt="" /></div><span className="d-block m--font-medium m--font-sm text-dark mb-1">Elizabeth G.
                  Rodiguez</span> <span className="d-block m--font-sm">elizabethrodiguez@teleworm.us</span>
              </div><a className="dropdown-item" href="#">View Profile</a> <a className="dropdown-item" href="#">Wallet</a> <a className="dropdown-item" href="#">Settings</a>
              <div className="dropdown-divider" /><a className="dropdown-item" href="#"><svg width="16px" height="16px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.99976 1L8.99976 9C8.99976 9.552 9.44776 10 9.99976 10C10.5528 10 10.9998 9.552 10.9998 9L10.9998 1C10.9998 0.447 10.5528 0 9.99976 0C9.44776 0 8.99976 0.447 8.99976 1ZM12.9998 2.12299C12.9998 1.27799 13.9018 0.751988 14.6488 1.14499C17.8308 2.81799 19.9998 6.15499 19.9998 9.99999C19.9998 15.9 14.8898 20.607 8.85382 19.936C4.30082 19.43 0.594823 15.742 0.0688234 11.191C-0.433177 6.84099 1.85882 2.96599 5.38282 1.12899C6.11782 0.744988 6.99982 1.25599 6.99982 2.08399L6.99982 2.17599C6.99982 2.57199 6.76382 2.91799 6.41282 3.10099C3.92482 4.39999 2.22282 7.00399 2.22282 9.99999C2.22282 14.581 6.20482 18.251 10.8898 17.728C14.3338 17.343 17.1868 14.602 17.6908 11.174C18.2008 7.71599 16.4158 4.60199 13.6348 3.12599C13.2558 2.92499 12.9998 2.55199 12.9998 2.12299Z" fill="#5B5F68" fillRule="evenodd" stroke="none" /></svg> <span className="d-inline-block ml-1">Logout</span></a>
            </div>
          </li> */}
        </ul>
      </aside >
    )
  }
}

export default withRouter(Header);