import React from "react";
import { Provider } from "react-redux";
import store from "./Store";
import App from "./App";
import "./index.css";
import Maintainance from "./Components/maintainance";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { isMaintaining } from "./config";

// localStorage.clear();

localStorage.setItem(
  "primary-color",
  getComputedStyle(document.documentElement).getPropertyValue("--primary-color")
);
localStorage.setItem(
  "primary-trans-color",
  getComputedStyle(document.documentElement).getPropertyValue(
    "--primary-trans-color"
  )
);
localStorage.setItem(
  "secondary-color",
  getComputedStyle(document.documentElement).getPropertyValue(
    "--secondary-color"
  )
);
localStorage.setItem(
  "bg-color",
  getComputedStyle(document.documentElement).getPropertyValue("--bg-color")
);
localStorage.setItem(
  "bg-dark-color",
  getComputedStyle(document.documentElement).getPropertyValue("--bg-dark-color")
);

if (isMaintaining) {
  document.body.classList.add("maintainance");
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    {isMaintaining ? (
      <Maintainance />
    ) : (
      <Router>
        <App />
      </Router>
    )}
  </Provider>
);
