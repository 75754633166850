import React, { useRef, useEffect, useState } from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import { enableAvatarView } from "../../config";
import { API_URL } from "../../config/env";

export const AvatarAnimetionsStack = {
  dancing: 0,
  Idle: 1,
  jump: 2,
  point_back: 3,
  rope_jump: 4,
  shurg: 5,
  talking_1: 6,
  talking_2: 7,
  wave: 8,
};
export const AvatarWardrobe = {
  Suit: "Suit",
  Formal: "Formal",
  Longjacket: "LongJacket",
  Redsweater: "RedSweater",
  hiteSweaterTie: "WhiteSweaterTie",
  FormalShirtTie: "FormalShirtTie",
};

const AvatarPath = {
  Formal: `${API_URL}/v1/avatar/formal.glb`,
  Suit: `${API_URL}/v1/avatar/suit.glb`,
  LongJacket: `${API_URL}/v1/avatar/long-jacket.glb`,
  FormalShirtTie: `${API_URL}/v1/avatar/formal-shirt-tie.glb`,
};

export default function Avatar({ animationType, clothName, ...props }) {
  const group = useRef();
  // const head = useRef();

  const Cloths = {
    Formal: useGLTF(AvatarPath.Formal),
    Suit: useGLTF(AvatarPath.Suit),
    LongJacket: useGLTF(AvatarPath.LongJacket),
    FormalShirtTie: useGLTF(AvatarPath.FormalShirtTie),
  };

  const { animations } = Cloths.Formal;
  const { actions, names } = useAnimations(animations, group);

  const [index, setIndex] = useState(
    animationType ?? AvatarAnimetionsStack.wave
  );
  const [avatar] = useState(Cloths[clothName ?? AvatarWardrobe.formal]);

  useEffect(() => {
    // console.log(names)
    // setTimeout(() => {
    //   // alert('hello')
    //   setIndex(AvatarAnimetionsStack.dancing);
    // }, 5000);
  }, []);

  useEffect(() => {
    if (actions[names[index]]) {
      actions[names[index]].repetitions = 1;
      actions[names[index]].reset().play();
      setTimeout(() => {
        if (actions[names[AvatarAnimetionsStack.Idle]]) {
          actions[names[AvatarAnimetionsStack.Idle]].repetitions = 1000;
          actions[names[AvatarAnimetionsStack.Idle]].play();
        }
      }, actions[names[index]].getClip().duration * 1000);
      // console.log(actions[names[AvatarAnimetionsStack.Idle]]);
    }

    return () => {
      // actions[names[index]].fadeOut(0.5);
    };
  }, [index, actions, names]);

  const { nodes, materials } = avatar;

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      onClick={() => {
        setIndex(AvatarAnimetionsStack.jump);
        setTimeout(() => {
          setIndex(AvatarAnimetionsStack.Idle);
        }, actions[names[AvatarAnimetionsStack.jump]].getClip().duration * 1000);
      }}
    >
      <primitive object={nodes.Hips} />
      <skinnedMesh
        geometry={nodes.Wolf3D_Body.geometry}
        material={materials.Wolf3D_Body}
        skeleton={nodes.Wolf3D_Body.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Glasses.geometry}
        material={materials.Wolf3D_Glasses}
        skeleton={nodes.Wolf3D_Glasses.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Hair.geometry}
        material={materials.Wolf3D_Hair}
        skeleton={nodes.Wolf3D_Hair.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
        material={materials.Wolf3D_Outfit_Bottom}
        skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
        material={materials.Wolf3D_Outfit_Footwear}
        skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Top.geometry}
        material={materials.Wolf3D_Outfit_Top}
        skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
      />
      <skinnedMesh
        name="EyeLeft"
        geometry={nodes.EyeLeft.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeLeft.skeleton}
        morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
      />
      <skinnedMesh
        name="EyeRight"
        geometry={nodes.EyeRight.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeRight.skeleton}
        morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Head"
        geometry={nodes.Wolf3D_Head.geometry}
        material={materials.Wolf3D_Skin}
        skeleton={nodes.Wolf3D_Head.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Teeth"
        geometry={nodes.Wolf3D_Teeth.geometry}
        material={materials.Wolf3D_Teeth}
        skeleton={nodes.Wolf3D_Teeth.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
      />
    </group>
  );
}

if (!!enableAvatarView) {
  useGLTF.preload(AvatarPath.Formal);
  useGLTF.preload(AvatarPath.Suit);
  useGLTF.preload(AvatarPath.LongJacket);
  useGLTF.preload(AvatarPath.FormalShirtTie);
}
