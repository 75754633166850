import React from 'react';
import './maintainance.css';
import construction from "../../Assets/images/under-construction-illustration.svg";

const Maintainance = (props) => {
  return (
    <div className="Maintainance">
      <h1>Under Construction</h1>
      <h2>We'll be back soon!</h2>
      <img className="illustration" src={construction} alt="Under Construction Illustration" />
      <p>We are doing important maintenance work on the website and will be back shortly. We apologise for any inconvenience caused.</p>
      <p className="special-text">Ankur Sanghvi</p>
    </div>
  )
}

export default Maintainance;