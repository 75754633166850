import React from "react";
import "./styles.css";

export default function Timeline({ data }) {
  return (
    <div className="main-container">
      <section id="timeline" className="timeline-outer">
        <div className="container" id="content">
          <div className="row">
            <div className="col s12 m12 l12">
              <ul className="timeline">
                {!!data.length &&
                  data.map((item) => (
                    <li className="event" data-date={item.date}>
                      <div className="time">{(() => {
                        let dates = item.date.split(' - ')
                        return <>
                        {dates.map(i => <div>{i}</div>)}
                        </>
                      })()}</div>
                      <h5 style={{margin:0}}>{item.title}</h5>
                      {!!item.subtext && <i>{item.subtext}</i>}
                      {!!item.body && <div className="body">
                        {!!item.body.length && <ul style={{marginLeft: 20}}>
                            {item.body.map(key => <li style={{listStyle: 'disc'}}>{key}</li>)}
                            </ul>}
                      </div>}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
