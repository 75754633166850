import React, { Component } from "react";
import "./project-details.css";
import projectList from "../../Assets/data/projects.json";
import Loader from "../../Components/loader";
import { Link } from "react-router-dom";
import Gallery from "../../Components/gallery";
import Helmet from "react-helmet";

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      projID: props.match.params.projectId.replace("-", " "),
      loader: true,
    };
    this.projData = projectList.filter(
      (proj) => proj.ProjectName === this.state.projID
    );
    if (this.projData.length > 0) {
      this.state.project = this.projData[0];
    } else {
      this.props.history.push("/project");
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ loader: false }), 3000);
  }

  render() {
    const { loader, project } = this.state;
    // const index = (projectList.filter(proj => proj.status).findIndex(proj => proj.ProjectName === project.ProjectName) > 0)
    //   ? projectList.filter(proj => proj.status).findIndex(proj => proj.ProjectName === project.ProjectName) - 1 : projectList.filter(proj => proj.status).length - 1
    // const projectName = projectList[index].ProjectName.replace(' ', '-');

    if (loader) {
      return <Loader isloading={loader} />;
    }
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="title"
            content={`${project.ProjectName} Project - Ankur Sanghvi`}
          />
          <meta
            name="description"
            content="I am Ankur Sanghvi, A Software Engineer and Freelancer. This page include all the neccessary information about my work and experience as well as my skill, from here anyone can contact me. All the neccessary contact details are provider on page"
          />
          <meta
            name="keywords"
            content="HTML5,CSS,JavaScript,PHP,JQuery,software engineer,web developer,freelancer,react developer,angular developer,Front End Developer, react native, reactjs"
          />
          <meta name="author" content="Ankur Sanghvi" />
          <title>{`${project.ProjectName} Project - Ankur Sanghvi`}</title>
          <link rel="canonical" href="https://ankursanghvi.in/project" />
        </Helmet>
        <main id="mainWrapper" className="projectDetails">
          <div id="pageHead">
            <div className="page-header"></div>
          </div>
          <div id="pageBody">
            <div id="pageContent">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <p style={{ marginBottom: "9vw" }}>
                    <Link to={`/project`} style={{ fontSize: "x-large" }}>
                      {" "}
                      <i className="fa fa-arrow-left"></i>{" "}
                    </Link>
                  </p>
                  <small className="type">
                    {/* {projectList
                      .filter((proj) => proj.status)
                      .findIndex(
                        (proj) => proj.ProjectName === project.ProjectName
                      ) +
                      1 <
                    10
                      ? `0${
                          projectList
                            .filter((proj) => proj.status)
                            .findIndex(
                              (proj) => proj.ProjectName === project.ProjectName
                            ) + 1
                        }`
                      : projectList
                          .filter((proj) => proj.status)
                          .findIndex(
                            (proj) => proj.ProjectName === project.ProjectName
                          ) + 1} */}
                  </small>
                  <br></br>
                  <small className="type"> {project.type} </small>
                  <h1 className="h1 head" style={{ color: "#fff" }}>
                    {project.ProjectName}
                  </h1>
                  <br />
                  <div className="desc"> {project.Description} </div>
                  {project.type === "Mobile App" && (
                    <div className="scroll-indicator"></div>
                  )}
                </div>
                {project.type === "Mobile App" ? (
                  <div className="col-sm-12 col-md-6">
                    <div
                      className={
                        this.state.windowWidth > 600
                          ? "phone view_1"
                          : "phone view_2"
                      }
                      id="phone_1"
                      style={{ width: "260px", height: "540px" }}
                    >
                      {/* <iframe src={project.Gallery[0].ImageUrl} title="hello" id="frame_1"></iframe> */}
                      <img
                        src={project.Gallery[0].ImageUrl}
                        className="d-block w-100"
                        alt={project.ProjectName}
                        data-type="image"
                        data-toggle="lightbox"
                        data-gallery="example-gallery"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="col-sm-12 col-md-6"
                    // style={{
                    //   background: `url(${
                    //     project.type.includes("Mobile")
                    //       ? project.logoUrl
                    //       : project.ImgUrl
                    //   })`,
                    //   backgroundSize: "cover",
                    // }}
                  >
                    <div className="laptop">
                      <div className="content">
                        <img
                          src={project.Gallery[0].ImageUrl}
                          className="d-block w-100"
                          alt={project.ProjectName}
                          data-type="image"
                          data-toggle="lightbox"
                          data-gallery="example-gallery"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="row banner-color client-banner mt-5"
                style={{
                  backgroundColor: project.bgColor,
                  color: project.fgColor,
                }}
              >
                {/* <div className="col-sm-2">
                  <small className="title-type">Client</small>
                  <p style={{ color: project.fgColor }}>
                    {project.client || "-"}
                  </p>
                </div> */}
                <div className="col-sm-2">
                  <small className="title-type">Year</small>
                  <p style={{ color: project.fgColor }}>
                    {project.year || "-"}
                  </p>
                </div>
                <div className="col-sm-3">
                  <small className="title-type">Technologies</small>
                  <p style={{ color: project.fgColor }}>
                    {project.stackList.join(", ")}
                  </p>
                </div>
                {!!project.WebUrl && false && (
                  <div className="col-sm-4 text-right">
                    <small className="title-type">&nbsp;</small>
                    <p style={{ color: project.fgColor }}>
                      <a href={project.WebUrl}>Visit</a>
                    </p>
                  </div>
                )}

                {/* <div className="col-sm-3 offset-sm-2 mt-4 pr-5 text-right">
              <Link to={`/work/${projectName}`}> <i className="fa fa-arrow-circle-right"></i> </Link>
            </div> */}
              </div>
              <div className="col-12 header">
                <h1 className="h1 head">Gallery</h1>
              </div>
              {project.type === "Mobile App" ? (
                <div className="row">
                  {project.Gallery.map((img) => (
                    <div className="col-sm-12 col-md-3">
                      <div
                        className="phone view_2"
                        id="phone_2"
                        style={{ width: "260px", height: "540px" }}
                      >
                        {/* <iframe src={project.Gallery[0].ImageUrl} title="hello" id="frame_1"></iframe> */}
                        <img
                          src={img.ImageUrl}
                          className="d-block w-100"
                          alt={project.ProjectName}
                          data-type="image"
                          data-toggle="lightbox"
                          data-gallery="example-gallery"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="row gallery">
                  <Gallery gallery={project.Gallery} />
                </div>
              )}
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default ProjectDetails;
