import React, { Component } from 'react';
import './float-button.css';


class FloatButton extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const { events, children } = this.props;
    return (
      <div className="FloatButton">
        <div className="">
          <div className="btn float d-flex align-items-center justify-content-center" {...events}>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default FloatButton;