import React, { Component } from "react";
import "./work.css";
import { connect } from "react-redux";
import FloatButton from "../../Components/float-button";
import Loader from "../../Components/loader";
import projectList from "../../Assets/data/projects.json";
import axios from "axios";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      switchView: false,
      projects: [...projectList].reverse().filter((proj) => proj.status),
      gitProjects: [],
      projectIndex: 0,
      isScrolled: true,
      isAnimate: "sildeInDown",
    };
    this.loadProjectData();
    // this.loadGithubProject();
  }

  paginate(array, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * 1, page_number * 1);
  }

  componentDidMount() {
    window.addEventListener("wheel", this.checkScrollDirection);
    window.addEventListener("keydown", this.keyboardArrowKeyPressHandler);
    window.addEventListener(
      "touchstart",
      (event) => {
        this.touchstartX = event.changedTouches[0].screenX;
        this.touchstartY = event.changedTouches[0].screenY;
      },
      false
    );

    window.addEventListener(
      "touchend",
      (event) => {
        this.touchendX = event.changedTouches[0].screenX;
        this.touchendY = event.changedTouches[0].screenY;
        this.handleGesure(event);
      },
      false
    );

    this.handleScroll = setInterval(() => {
      this.setState({
        isScrolled: true,
      });
    }, 2000);
  }

  handleGesure = (event) => {
    if (this.touchendY < this.touchstartY) {
      this.scrollDown(event);
      // this.setState({
      //   isAnimate: 'slideOutUp',
      //   isScrolled: false
      // }, () => {
      //   if (!this.state.isScrolled)
      //     setTimeout(() => {
      //       this.setState({
      //         isAnimate: 'sildeInDown',
      //         projectIndex: (this.state.projectIndex < this.state.projects.length - 1)
      //           ? this.state.projectIndex + 1 : 0,
      //       })
      //     }, 1000)
      // })
    }
    if (this.touchendY > this.touchstartY) {
      this.scrollUp(event);
      // this.setState({
      //   isAnimate: 'slideOutDown',
      //   isScrolled: false
      // }, () => {
      //   if (!this.state.isScrolled)
      //     setTimeout(() => {
      //       this.setState({
      //         isAnimate: 'sildeInUp',
      //         projectIndex: (this.state.projectIndex > 0)
      //           ? this.state.projectIndex - 1 : this.state.projects.length - 1,
      //       })
      //     }, 1000)
      // })
    }
  };

  componentWillUnmount() {
    window.removeEventListener("wheel", this.checkScrollDirection);
    window.removeEventListener("keydown", this.keyboardArrowKeyPressHandler);
    clearTimeout(this.handleScroll);
  }

  checkScrollDirection = (event) => {
    if (event.type === "wheel") {
      this.wheelEvent(event);
    }
  };

  scrollUp = (event) => {
    // console.log('UP', event.deltaY, this.state.projectIndex)
    this.setState(
      {
        isAnimate: "slideOutDown",
        isScrolled: false,
      },
      () => {
        if (!this.state.isScrolled)
          setTimeout(() => {
            this.setState({
              isAnimate: "sildeInUp",
              projectIndex:
                this.state.projectIndex > 0
                  ? this.state.projectIndex - 1
                  : this.state.projects.length - 1,
            });
          }, 1000);
      }
    );
  };

  scrollDown = (event) => {
    // console.log('Down', event.deltaY, this.state.projectIndex);
    this.setState(
      {
        isAnimate: "slideOutUp",
        isScrolled: false,
      },
      () => {
        if (!this.state.isScrolled)
          setTimeout(() => {
            this.setState({
              isAnimate: "sildeInDown",
              projectIndex:
                this.state.projectIndex < this.state.projects.length - 1
                  ? this.state.projectIndex + 1
                  : 0,
            });
          }, 1000);
      }
    );
  };

  keyboardArrowKeyPressHandler = (event) => {
    if (event.keyCode === 37 || event.keyCode === 38) {
      this.scrollUp(event);
    } else if (event.keyCode === 39 || event.keyCode === 40) {
      this.scrollDown(event);
    }
  };

  wheelEvent = (event) => {
    if (this.checkScrollDirectionIsUp(event)) {
      if (event.deltaY <= -1 && this.state.isScrolled) {
        this.scrollUp(event);
      }
    } else {
      if (event.deltaY >= 1 && this.state.isScrolled) {
        this.scrollDown(event);
      }
    }
  };

  checkScrollDirectionIsUp = (event) => {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 1;
  };

  loadProjectData = () => {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  };

  loadGithubProject = () => {
    const that = this;
    axios({
      url: "https://api.github.com/users/techbot11/repos",
      method: "GET",
    }).then((resp) => {
      // console.log(resp)
      that.setState({
        gitProjects: resp.data,
      });
    });
  };

  render() {
    const {
      switchView,
      loading,
      projects,
      gitProjects,
      projectIndex,
      isAnimate,
    } = this.state;

    if (loading) {
      return <Loader isloading={loading} />;
    }

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="title" content="Projects - Ankur Sanghvi" />
          <meta
            name="description"
            content="I am Ankur Sanghvi, A Software Engineer and Freelancer. This page include all the neccessary information about my work and experience as well as my skill, from here anyone can contact me. All the neccessary contact details are provider on page"
          />
          <meta
            name="keywords"
            content="HTML5,CSS,JavaScript,PHP,JQuery,software engineer,web developer,freelancer,react developer,angular developer,Front End Developer, react native, reactjs"
          />
          <meta name="author" content="Ankur Sanghvi" />
          {/* <title>Projects - Ankur Sanghvi</title> */}
          <link rel="canonical" href="https://ankursanghvi.in/project" />
        </Helmet>
        <main id="mainWrapper" className="work">
          <div id="pageHead">
            <div className="page-header"></div>
          </div>
          <div id="pageBody">
            <div id="pageContent">
              {switchView ? (
                <div className="row">
                  <div className="col-sm-12 mb-4">
                    <h1 className="h2 head pull-left">My Work</h1>
                  </div>
                  {gitProjects.length
                    ? gitProjects.map((proj, i) => (
                        <div className="col-sm-6 mb-4" key={i}>
                          <div className="card text-white bg-dark bg-card">
                            <div className="card-img-top text-center">
                              <div className="hovereffect">
                                <span className="icon">
                                  <img
                                    className="img-fluid"
                                    alt="hello"
                                    src="https://img.icons8.com/emoji/96/000000/sun-emoji.png"
                                  />
                                </span>
                                <img
                                  className="img-fluid img-responsive project-img"
                                  src={proj.owner.avatar_url}
                                  alt={proj.name}
                                />
                                <div className="overlay">
                                  <h2>{proj.name}</h2>
                                  <button
                                    data-toggle="modal"
                                    data-target="#exampleModalLong"
                                    className="info btn btn-outline-success"
                                  >
                                    {" "}
                                    <i className="fa fa-eye"></i>{" "}
                                  </button>
                                </div>
                              </div>
                              {/* <img className="img-fluid project-img" src={proj.ImgUrl} alt={proj.ProjectName} /> */}
                            </div>
                            {/* <div className="card-body">
                          <h5 className="card-title">{proj.ProjectName}</h5>
                          <p className="desc">{proj.Description}</p>
                          <a href="true" className="btn btn-outline-success"> <i className="fa fa-share"></i> </a>
                        </div> */}
                          </div>
                        </div>
                      ))
                    : null}
                  {projects &&
                    projects
                      .filter((p) => p.status)
                      .map((proj, i) => (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 mb-4"
                          key={i}
                        >
                          <div className="card text-white bg-dark bg-card">
                            <img
                              className="card-img-top"
                              src={proj.ImgUrl}
                              alt={proj.ProjectName}
                            />
                            <div className="card-body">
                              <h6 className="h6">{proj.ProjectName}</h6>
                              <p className="card-text text-muted">
                                {proj.Description}
                              </p>
                              <p className="card-text">
                                <small className="text-muted">
                                  {" "}
                                  <Link
                                    to={`project/${proj.ProjectName.replace(
                                      " ",
                                      "-"
                                    )}`}
                                  >
                                    Read More
                                  </Link>
                                </small>
                              </p>
                            </div>
                          </div>
                          {/* <div className="card text-white">
                          <img className="card-img" src={proj.ImgUrl} alt={proj.ProjectName} />
                          <div className="card-img-overlay">
                            <h6 className="h6">{proj.ProjectName}</h6>
                            <button data-toggle="modal" data-target="#exampleModalLong" onClick={e => this.setState({ modalData: proj })} className="info btn btn-outline-success"> <i className="fa fa-eye"></i> </button>
                          </div>
                        </div> */}
                        </div>
                      ))}
                </div>
              ) : (
                <>
                  {projects &&
                    projects.map((proj, i) => (
                      <div
                        key={i}
                        className={`row work-screen ${
                          i === projectIndex ? "" : "inactive"
                        }`}
                      >
                        {/* <div className="col-sm-1 col-md-2 col-lg-2 col-xl-3 flex-padding-left-g extra-padding-left">
                        <h1 className="h2 head pull-left">My Work</h1>
                      </div> */}
                        <div className="col-sm-8 col-md-6 offset-md-2 col-lg-6 offset-lg-2 col-xl-5 offset-xl-3">
                          <div
                            className={`d-flex justify-content-md-center align-items-center`}
                          >
                            <svg
                              className="project-svg"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 640 640"
                              width={640}
                              height={640}
                              onClick={(e) => {
                                this.props.history.push(
                                  `project/${proj.ProjectName.replace(
                                    " ",
                                    "-"
                                  )}`
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <defs>
                                <path
                                  d="M127.03 59.26L512.97 59.26L512.97 602.57L127.03 602.57L127.03 59.26Z"
                                  id="ePW2Ir12y"
                                />
                                <path
                                  d="M406.27 14.57L566.72 544.84L215.74 651.04L55.29 120.77L406.27 14.57Z"
                                  id="byZvlMvjN"
                                />
                                <clipPath
                                  className="animated fadeIn"
                                  id="clipcpHvoaMOx"
                                >
                                  <use xlinkHref="#byZvlMvjN" opacity={1} />
                                </clipPath>
                                <path
                                  d="M172.42 106.95L469.92 106.95L469.92 560.66L172.42 560.66L172.42 106.95Z"
                                  id="aFis7B28F"
                                />
                                <path
                                  d="M260 260L380 260L380 380L260 380L260 260Z"
                                  id="bgezUlz3i"
                                />
                                <clipPath id="clipa4kBkVjg7P">
                                  <use xlinkHref="#bgezUlz3i" opacity={1} />
                                </clipPath>
                              </defs>
                              <g>
                                <g>
                                  {/* <g className={`animated fade${isAnimate.includes('Out') ? 'Out' : 'In'}${isAnimate === 'sildeInDown' ? 'Left' : 'Right'}`}> */}
                                  <g
                                    className={`animated slower fade${
                                      isAnimate === "sildeInDown"
                                        ? "In"
                                        : isAnimate === "sildeInUp"
                                        ? "In"
                                        : "Out"
                                    }`}
                                  >
                                    <use
                                      xlinkHref="#ePW2Ir12y"
                                      opacity={1}
                                      fill={proj.bgColor}
                                      fillOpacity={1}
                                    />
                                    <g>
                                      <use
                                        xlinkHref="#ePW2Ir12y"
                                        opacity={1}
                                        fillOpacity={0}
                                        stroke={proj.bgColor}
                                        strokeWidth={1}
                                        strokeOpacity={1}
                                      />
                                    </g>
                                  </g>
                                  <g>
                                    <g
                                      className={`animated fadeIn${
                                        isAnimate === "sildeInDown"
                                          ? "Up"
                                          : "Down"
                                      } ${isAnimate}`}
                                      clipPath="url(#clipcpHvoaMOx)"
                                      opacity={1}
                                    >
                                      <image
                                        href={proj.ImgUrl}
                                        x={0}
                                        y={0}
                                        width={620}
                                        height="382.31"
                                        transform="matrix(0.23596135317037029 0.7798074053813188 -0.918048524086797 0.27778407705671193 406.26978962784835 14.566929645293744)"
                                      />
                                    </g>
                                  </g>
                                  <g>
                                    <use
                                      xlinkHref="#aFis7B28F"
                                      opacity={1}
                                      fill="#ffffff"
                                      fillOpacity={1}
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <div
                              className="overlay animated slower fadeIn text-center special-text"
                              style={{ color: proj.bgColor }}
                            >
                              <span
                                onClick={(e) => {
                                  this.props.history.push(
                                    `project/${proj.ProjectName.replace(
                                      " ",
                                      "-"
                                    )}`
                                  );
                                }}
                              >
                                {proj.ProjectName}
                              </span>
                            </div>
                          </div>
                          <div className="justify-content-md-center align-items-center text-center">
                            <svg
                              onClick={this.scrollUp}
                              className="side-nav-icon"
                              width="24"
                              height="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
                            </svg>

                            <svg
                              onClick={this.scrollDown}
                              className="side-nav-icon"
                              width="24"
                              height="24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                            </svg>
                          </div>
                        </div>
                        <div
                          className={`col-md-4 text-right animated slide${
                            isAnimate === "sildeInDown"
                              ? "In"
                              : isAnimate === "sildeInUp"
                              ? "In"
                              : "Out"
                          }Right d-flex justify-content-end align-items-center second-half`}
                        >
                          <div className="right-column">
                            <p className="page-count">
                              <b>No. {i + 1 < 10 ? `0${i + 1}` : i + 1}</b> ___{" "}
                              <span>
                                {projects.length < 10
                                  ? `0${projects.length}`
                                  : projects.length}
                              </span>
                            </p>
                            <div className="desc"> {proj.type} </div>
                            <h1 className="h1">{proj.ProjectName}</h1>
                            <span className="desc"> {proj.Description} </span>
                            <div> <p style={{color: '#fff', fontSize: 14}}> <b>Stack:</b> {proj.stackList.join(", ")} </p> </div>

                            <p className="no-margin mt-5 link">
                              <Link
                                to={`${`project/${proj.ProjectName.replace(
                                  " ",
                                  "-"
                                )}`}`}
                              >
                                {" "}
                                read more{" "}
                              </Link>
                              {/* <Link to={`/work/${`project/${proj.ProjectName.replace(' ', '-')}`}`}> <i className="fa fa-arrow-circle-right"></i> </Link> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/* <footer className="project-menu grid">
                  <div className="container">
                    hello
                  </div>
                </footer> */}
                </>
              )}
              <FloatButton
                events={{
                  onClick: () => this.setState({ switchView: !switchView }),
                }}
              >
                {!switchView ? <i className="fa fa-th"></i> : "3D"}
              </FloatButton>
            </div>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Work);
