const { ENV_TYPE } = require("./env");

module.exports = {
  isMaintaining: false,
  showGithub: false,
  isBlogActive: false,
  isMousetrapActive: false,
  enableAvatarView: false,
  isProduction: ENV_TYPE === "PRODUCTION",
};
