import React, { Component } from "react";
import "./contact.css";
import Loader from "../../Components/loader";
import Scene from "../../Components/3D/Scene";
import { enableAvatarView } from "../../config";
import { AvatarWardrobe } from "../../Components/3D/Avatar";
import Helmet from "react-helmet";
// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div className="h4 marker"> <i className="fa fa-map-marker"></i> </div>;

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      hasSwitch: false,
    };
  }

  static defaultProps = {
    center: {
      lat: 12.91001,
      lng: 77.676928,
    },
    zoom: 11,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ loader: false }), 3000);
  }

  componentWillUnmount() {
    this.setState({
      hasSwitch: true,
    });
  }

  onSend = () => {
    // const { name, email, subject, message } = this.state;
    // EmailService.sendMail(name, email, subject, message)
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { loader } = this.state;

    if (loader) {
      return <Loader isloading={loader} />;
    }

    const isMobileView = window.innerWidth < 600;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="title" content="Contact Me - Ankur Sanghvi" />
          <meta
            name="description"
            content="I am Ankur Sanghvi, A Software Engineer and Freelancer. This page include all the neccessary information about my work and experience as well as my skill, from here anyone can contact me. All the neccessary contact details are provider on page"
          />
          <meta
            name="keywords"
            content="HTML5,CSS,JavaScript,PHP,JQuery,software engineer,web developer,freelancer,react developer,angular developer,Front End Developer, react native, reactjs"
          />
          <meta name="author" content="Ankur Sanghvi" />
          {/* <title>Contact Me - Ankur Sanghvi</title> */}
          <link rel="canonical" href="https://ankursanghvi.in/contact" />
        </Helmet>
        <main id="mainWrapper" className="contact">
          <div id="pageHead">
            <div className="page-header"></div>
          </div>
          <div id="pageBody">
            <div id="pageContent">
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-6 text-center mobile">
                  {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyCl4SUhqLQmotb4oKxm0umL1UE-nwN_hrk' }}
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                >
                  <AnyReactComponent
                    lat={this.props.center.lat}
                    lng={this.props.center.lng}
                    text="Here I'm"
                  />
                </GoogleMapReact> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 600 500"
                    id="contact-svg"
                    version="1.1"
                  >
                    <g
                      transform="matrix(1.3333333,0,0,-1.3333333,0,600)"
                      id="g10"
                    >
                      <g transform="scale(0.1)" id="g12">
                        {/* <path id="path16" style={{ fill: '#85a9dd', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} d="m 4292.05,2590.25 c 0,-998.9 -809.76,-1808.648 -1808.65,-1808.648 -998.89,0 -1808.654,809.748 -1808.654,1808.648 0,998.89 809.764,1808.66 1808.654,1808.66 998.89,0 1808.65,-809.77 1808.65,-1808.66" /> */}
                        <path
                          id="path18"
                          style={{
                            fill: "#e2e2e2",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3379.37,1306.33 c -823.56,0 -1647.12,0 -2470.686,0 -45.231,0 -71.871,28.78 -80.012,62.71 -11.695,-48.82 14.949,-108.32 80.012,-108.32 823.566,0 1647.126,0 2470.686,0 65.06,0 91.71,59.5 80.01,108.32 -8.13,-33.93 -34.78,-62.71 -80.01,-62.71"
                        />
                        <path
                          id="path20"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 908.684,1306.33 c 823.566,0 1647.126,0 2470.686,0 45.23,0 71.88,28.78 80.01,62.71 -8.13,33.95 -34.78,62.72 -80.01,62.72 -823.56,0 -1647.12,0 -2470.686,0 -45.231,0 -71.871,-28.77 -80.012,-62.72 8.141,-33.93 34.781,-62.71 80.012,-62.71"
                        />
                        <path
                          id="path22"
                          style={{
                            fill: "#000000",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2678.02,3131.54 c 243.25,45.61 83.61,577.7 -15.21,676.51 -114.01,106.42 -334.46,53.21 -478.86,83.62 -152.03,22.8 -334.47,91.21 -402.88,-129.23 -38,-129.23 45.61,-311.65 60.82,-440.87 15.2,-144.42 0,-243.24 174.82,-235.64 l 661.31,45.61"
                        />
                        <path
                          id="path24"
                          style={{
                            fill: "#eda18a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2195.53,2804.59 c 0,-51.93 0,-259.69 0,-311.64 h 222.61 c 0,51.95 0,259.71 0,311.64 h -222.61"
                        />
                        <path
                          id="path26"
                          style={{
                            fill: "#fdb498",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1923.54,3648.54 c -31.16,-311.76 -74.81,-848 336.71,-885.4 361.63,-37.43 461.41,280.58 448.94,573.64 0,81.07 -37.4,261.89 -62.36,342.93 l -723.29,-31.17"
                        />
                        <path
                          id="path28"
                          style={{
                            fill: "#000000",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2272.65,2505.47 c 124.56,-10.37 215.37,32.29 250.33,149.97 -35.2,4.98 -70.33,5.33 -104.84,0.76 -14.84,-140.99 -207.76,-92.76 -237.45,-3.7 -31.61,0.63 -62.04,-2.06 -91.5,-7.22 11.39,-95.44 94.94,-134.6 183.46,-139.81"
                        />
                        <path
                          id="path30"
                          style={{
                            fill: "var(--primary-color)",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2693.02,3470.1 c 238.8,82.98 270.93,-376.67 -5.19,-340.41 3.02,75.57 1.16,239.65 5.19,340.41"
                        />
                        <path
                          id="path32"
                          style={{
                            fill: "var(--primary-color)",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1850.82,3398.38 c 36.39,223.65 235.32,357.85 452.52,370.5 219.29,12.77 425.61,-114.01 446.73,-344.59 3.99,-43.56 72.43,-43.94 68.41,0 -52.34,571.42 -947.37,522.48 -1033.62,-7.72 -7.02,-43.15 58.89,-61.65 65.96,-18.19 v 0"
                        />
                        <path
                          id="path34"
                          style={{
                            fill: "var(--primary-color)",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1829.05,3177.78 c -2.03,-91.45 -46.03,-224.79 53.86,-278.95 87.45,-47.4 206.89,-27.15 301.89,-26.39 32.5,0.26 32.53,50.68 0,50.42 -83.57,-0.66 -169.02,-10.46 -251.03,10.36 -104.01,26.41 -56.03,166.28 -54.31,244.56 0.73,32.54 -49.7,32.46 -50.41,0 v 0"
                        />
                        <path
                          id="path36"
                          style={{
                            fill: "var(--primary-color)",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1912.06,3470.1 c -238.82,82.98 -270.93,-376.67 5.17,-340.41 -3.02,75.57 -1.16,239.65 -5.17,340.41"
                        />
                        <path
                          id="path38"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2522.98,2655.44 c -6.74,-22.69 -15.6,-42.53 -26.34,-59.8 h 195.19 c -54.93,31.43 -111.98,51.77 -168.85,59.8"
                        />
                        <path
                          id="path40"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1553.82,2048.35 h 1508.04 c -8.69,35.55 -18.76,69.78 -30.15,102.62 H 1590.98 c -13.86,-33.94 -26.2,-68.28 -37.16,-102.62"
                        />
                        <path
                          id="path42"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2089.19,2645.28 c -50.76,-8.91 -98.32,-25.98 -142.66,-49.64 h 157.2 c -7.26,14.53 -12.32,30.98 -14.54,49.64"
                        />
                        <path
                          id="path44"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1473.5,1501.06 h 1676.99 l -15.75,102.61 h -1651.1 l -10.14,-102.61"
                        />
                        <path
                          id="path46"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1626.74,2230.79 h 1373.47 c -16,36.22 -33.58,70.54 -52.64,102.6 H 1683.33 c -20.53,-32.92 -39.35,-67.35 -56.59,-102.6"
                        />
                        <path
                          id="path48"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1491.52,1683.49 h 1630.95 l -15.76,102.61 H 1501.67 l -10.15,-102.61"
                        />
                        <path
                          id="path50"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2272.65,2505.47 c -21.76,1.28 -43.19,4.64 -63.35,10.36 h -375.58 c -34.2,-30.53 -65.94,-65.01 -95.04,-102.62 h 1155.28 c -29.11,38.47 -60.44,72.7 -93.33,102.62 h -408.45 c -34.9,-10.85 -75.01,-14.07 -119.53,-10.36"
                        />
                        <path
                          id="path52"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1510.69,1877.47 -1.14,-11.55 h 1584.9 l -9.11,59.4 c -2.02,14.6 -4.31,28.99 -6.81,43.22 H 1530.79 c -7.89,-30.8 -14.59,-61.26 -20.1,-91.07"
                        />
                        <path
                          id="path54"
                          style={{
                            fill: "var(--primary-color)",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2496.64,2595.64 c -25.04,-40.28 -60.57,-66.16 -104.46,-79.81 h 408.45 c -34.76,31.64 -71.27,58.34 -108.8,79.81 h -195.19"
                        />
                        <path
                          id="path56"
                          style={{
                            fill: "var(--primary-color)",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2103.73,2595.64 h -157.2 c -40.29,-21.5 -77.84,-48.59 -112.81,-79.81 h 375.58 c -45.83,12.97 -84.88,38.32 -105.57,79.81"
                        />
                        <path
                          id="path58"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1683.33,2333.39 h 1264.24 c -16.84,28.3 -34.77,54.92 -53.61,79.82 H 1738.68 c -19.61,-25.35 -38.06,-52.07 -55.35,-79.82"
                        />
                        <path
                          id="path60"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1590.98,2150.97 h 1440.73 c -9.6,27.62 -20.17,54.19 -31.5,79.82 H 1626.74 c -12.81,-26.21 -24.77,-52.86 -35.76,-79.82"
                        />
                        <path
                          id="path62"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1530.79,1968.54 h 1547.74 c -4.74,27.3 -10.34,53.89 -16.67,79.81 H 1553.82 c -8.51,-26.7 -16.26,-53.39 -23.03,-79.81"
                        />
                        <path
                          id="path64"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1501.67,1786.1 h 1605.04 l -12.26,79.82 h -1584.9 l -7.88,-79.82"
                        />
                        <path
                          id="path66"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1483.64,1603.67 h 1651.1 l -12.27,79.82 H 1491.52 l -7.88,-79.82"
                        />
                        <path
                          id="path68"
                          style={{
                            fill: "var(--primary-color)",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1465.62,1421.24 h 1697.12 l -12.25,79.82 H 1473.5 l -7.88,-79.82"
                        />
                        <path
                          id="path70"
                          style={{
                            fill: "#0a3470",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2061.48,3239.74 c 23.3,72.68 113.93,69.13 149.41,8.05 5.54,-9.53 20.33,-0.92 14.77,8.63 -43.35,74.61 -153.03,74.12 -180.67,-12.13 -3.37,-10.54 13.13,-15.01 16.49,-4.55 v 0"
                        />
                        <path
                          id="path72"
                          style={{
                            fill: "#0a3470",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2430.13,3247.34 c 23.3,72.68 113.92,69.12 149.42,8.05 5.53,-9.53 20.31,-0.92 14.77,8.63 -43.35,74.6 -153.03,74.12 -180.67,-12.14 -3.38,-10.53 13.13,-15 16.48,-4.54 v 0"
                        />
                        <path
                          id="path74"
                          style={{
                            fill: "#e89a82",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2320.76,2901.6 c 90.29,3.52 184.92,75.79 206.52,164.58 5.22,21.43 -27.77,30.52 -32.97,9.1 -17.94,-73.68 -98.14,-136.53 -173.55,-139.47 -21.99,-0.86 -22.07,-35.08 0,-34.21 v 0"
                        />
                        <path
                          id="path76"
                          style={{
                            fill: "#000000",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1879.9,3640.83 c 159.62,-174.83 630.9,-205.24 843.72,-205.24 0,0 76.01,311.66 -349.65,334.46 -425.66,22.8 -494.07,-129.22 -494.07,-129.22"
                        />
                        <path
                          id="path78"
                          style={{
                            fill: "#e89a82",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2341.32,3257.9 28.3,-163.07 c 5.11,-29.43 -14.61,-57.44 -44.05,-62.55 -29.43,-5.11 -57.44,14.61 -62.54,44.05 -1.09,6.23 -1,12.61 0,18.5 l 28.29,163.07 c 2.4,13.81 15.54,23.06 29.34,20.66 10.83,-1.88 18.87,-10.42 20.66,-20.66"
                        />
                        <path
                          id="path80"
                          style={{
                            fill: "#8c85a9",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3019.22,2466.31 h -57.01 c 61.32,0 111.07,-40.48 111.07,-90.42 v -786.71 c 0,-49.95 -49.75,-90.44 -111.07,-90.44 h 57.01 c 61.34,0 111.06,40.49 111.06,90.44 v 786.71 c 0,49.94 -49.72,90.42 -111.06,90.42"
                        />
                        <path
                          id="path82"
                          style={{
                            fill: "#b6b4cc",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1500.22,1589.18 v 786.71 c 0,49.94 49.74,90.42 111.07,90.42 h -45.61 c -61.34,0 -111.07,-40.48 -111.07,-90.42 v -786.71 c 0,-49.95 49.73,-90.44 111.07,-90.44 h 45.61 c -61.33,0 -111.07,40.49 -111.07,90.44"
                        />
                        <path
                          id="path84"
                          style={{
                            fill: "#a4a0bc",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3073.28,1589.18 v 786.71 c 0,49.94 -49.75,90.42 -111.07,90.42 H 1611.29 c -61.33,0 -111.07,-40.48 -111.07,-90.42 v -786.71 c 0,-49.95 49.74,-90.44 111.07,-90.44 h 1350.92 c 61.32,0 111.07,40.49 111.07,90.44"
                        />
                        <path
                          id="path86"
                          style={{
                            fill: "#706b8e",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2093.24,1494.44 -89.12,3.19 119.71,473.51 c 9.49,62.24 67.65,105.03 129.9,95.52 l 67.62,-10.31 c 62.25,-9.48 105.03,-67.64 95.54,-129.89 l -1.1,-461.18 c -0.12,-0.77 -0.33,-1.5 -0.46,-2.27 l -322.09,31.43"
                        />
                        <path
                          id="path88"
                          style={{
                            fill: "#8c85a9",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2503.19,1501.06 c 0,-62.96 -51.06,-114.02 -114.02,-114.02 h -205.23 c -62.97,0 -114.02,51.06 -114.02,114.02 l 68.41,456.07 c 0,62.98 51.05,114.03 114.02,114.03 h 68.41 c 62.97,0 114.02,-51.05 114.02,-114.03 l 68.41,-456.07"
                        />
                        <path
                          id="path90"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1176.8,1919.05 -109.82,19.93 c 0.62,-13.62 2.06,-27.21 4.3,-40.59 l 97.95,-17.76 136.2,63.61 c 3.31,12.81 6.1,25.81 8.39,38.91 l -137.01,-63.99 -0.01,-0.11"
                        />
                        <path
                          id="path92"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1172.25,1858.15 -96.27,17.48 c 3.63,-14.64 8.41,-28.85 14.41,-42.44 l 74.29,-13.48 115.67,54.03 c 6.47,13.7 12.12,28.09 17,42.93 l -125.07,-58.44 -0.03,-0.08"
                        />
                        <path
                          id="path94"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1185.9,2040.89 -105.37,19.12 c -3.37,-12.32 -6.19,-25.14 -8.41,-38.3 l 106.21,-19.27 143.66,67.1 c 0.18,11.83 -0.01,23.42 -0.56,34.73 l -135.52,-63.31 -0.01,-0.07"
                        />
                        <path
                          id="path96"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1181.35,1979.98 -112.22,20.36 c -1.47,-12.96 -2.32,-26.14 -2.49,-39.38 l 107.14,-19.43 143.9,67.23 c 1.53,12.24 2.68,24.46 3.4,36.58 l -139.71,-65.28 -0.02,-0.08"
                        />
                        <path
                          id="path98"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1199.38,2378.59 c -24.84,28.46 -51.55,56.64 -78.14,87.19 5.9,-30.76 9.18,-60.64 10.46,-90.09 l 44.27,-8.03 23.41,10.93"
                        />
                        <path
                          id="path100"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1240.38,2328.27 c -8.42,11.33 -17.26,22.4 -26.43,33.31 l -34.95,-16.29 -0.02,-0.1 -46.71,8.48 c 0.09,-12 -0.06,-23.97 -0.62,-35.89 l 62.15,-11.28 46.58,21.77"
                        />
                        <path
                          id="path102"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1167.7,1797.24 -64.9,11.78 c 10.92,-18.35 24.52,-35.04 41.2,-49.26 16.22,5.21 46.37,8.94 60.85,19.59 23.05,15.92 42.29,37.4 58.08,62.45 l -95.21,-44.48 -0.02,-0.08"
                        />
                        <path
                          id="path104"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1199.55,2223.61 -76.32,13.86 c -2.02,-12.84 -4.31,-25.67 -6.92,-38.57 l 75.66,-13.72 102.82,48.03 c -3.97,9.96 -8.32,19.56 -13.02,28.89 l -82.21,-38.39 -0.01,-0.1"
                        />
                        <path
                          id="path106"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1194.99,2162.71 -83.16,15.09 c -2.81,-12.66 -5.86,-25.37 -9.14,-38.16 l 84.74,-15.38 123.52,57.7 c -2.51,10.68 -5.46,20.97 -8.74,30.91 L 1195,2162.8 l -0.01,-0.09"
                        />
                        <path
                          id="path108"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1196.83,2284.12 -0.01,-0.08 -66.57,12.09 c -1,-12.46 -2.34,-24.89 -3.96,-37.3 l 70.22,-12.75 75.02,35.05 c -5.77,10.05 -11.93,19.77 -18.41,29.3 l -56.29,-26.31"
                        />
                        <path
                          id="path110"
                          style={{
                            fill: "#917f17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1190.44,2101.79 -93.21,16.91 c -3.4,-12.5 -6.97,-25.15 -10.68,-37.87 l 96.32,-17.47 136.91,63.93 c -1.03,10.73 -2.37,21.1 -4.06,30.96 -0.11,0.7 -0.24,1.3 -0.36,1.97 l -124.9,-58.33 -0.02,-0.1"
                        />
                        <path
                          id="path112"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1090.39,1833.19 c 3.67,-8.32 7.79,-16.39 12.41,-24.17 l 64.9,-11.78 0.02,0.08 95.21,44.48 c 6.37,10.09 12.16,20.8 17.42,31.94 l -115.67,-54.03 -74.29,13.48"
                        />
                        <path
                          id="path114"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1071.28,1898.39 c 1.28,-7.67 2.84,-15.27 4.7,-22.76 l 96.27,-17.48 0.03,0.08 125.07,58.44 c 2.98,9.04 5.66,18.25 8.08,27.57 l -136.2,-63.61 -97.95,17.76"
                        />
                        <path
                          id="path116"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1066.64,1960.96 c -0.09,-7.32 0.01,-14.65 0.34,-21.98 l 109.82,-19.93 0.01,0.11 137.01,63.99 c 1.5,8.51 2.79,17.05 3.86,25.61 l -143.9,-67.23 -107.14,19.43"
                        />
                        <path
                          id="path118"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1072.12,2021.71 c -1.17,-7.03 -2.17,-14.17 -2.99,-21.37 l 112.22,-20.36 0.02,0.08 139.71,65.28 c 0.49,8.14 0.77,16.2 0.91,24.2 l -143.66,-67.1 -106.21,19.27"
                        />
                        <path
                          id="path120"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1086.55,2080.83 c -0.89,-3.04 -1.74,-6.06 -2.65,-9.11 -1.18,-3.85 -2.3,-7.77 -3.37,-11.71 l 105.37,-19.12 0.01,0.07 135.52,63.31 c -0.38,7.83 -0.94,15.52 -1.65,23.02 l -136.91,-63.93 -96.32,17.47"
                        />
                        <path
                          id="path122"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1102.69,2139.64 c -1.77,-6.96 -3.57,-13.93 -5.46,-20.94 l 93.21,-16.91 0.02,0.1 124.9,58.33 c -1.29,7.44 -2.76,14.68 -4.41,21.74 l -123.52,-57.7 -84.74,15.38"
                        />
                        <path
                          id="path124"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1116.31,2198.9 c -1.41,-7.03 -2.92,-14.07 -4.48,-21.1 l 83.16,-15.09 0.01,0.09 107.21,50.07 c -2.3,6.93 -4.77,13.71 -7.42,20.34 l -102.82,-48.03 -75.66,13.72"
                        />
                        <path
                          id="path126"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1126.29,2258.83 c -0.94,-7.11 -1.94,-14.24 -3.06,-21.36 l 76.32,-13.86 0.01,0.1 82.21,38.39 c -3.26,6.47 -6.68,12.81 -10.24,19.03 l -75.02,-35.05 -70.22,12.75"
                        />
                        <path
                          id="path128"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1196.83,2284.12 56.29,26.31 c -4.14,6.02 -8.37,11.97 -12.74,17.84 l -46.58,-21.77 -62.15,11.28 c -0.35,-7.21 -0.83,-14.45 -1.4,-21.65 l 66.57,-12.09 0.01,0.08"
                        />
                        <path
                          id="path130"
                          style={{
                            fill: "#776c0a",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1179,2345.29 34.95,16.29 c -4.78,5.71 -9.63,11.38 -14.57,17.01 l -23.41,-10.93 -44.27,8.03 c 0.32,-7.36 0.51,-14.69 0.57,-22.02 l 46.71,-8.48 0.02,0.1"
                        />
                        <path
                          id="path132"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1111.05,1915.39 -108.12,-27.72 c 6.26,-12.12 13.22,-23.9 20.85,-35.11 l 96.43,24.72 97.23,114.63 c -2.34,13.04 -5.21,26.01 -8.6,38.88 l -97.81,-115.33 0.02,-0.07"
                        />
                        <path
                          id="path134"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1132.33,1858.12 -94.79,-24.29 c 9.42,-11.78 19.68,-22.72 30.8,-32.56 l 73.15,18.76 82.57,97.34 c 0.17,15.17 -0.71,30.6 -2.45,46.12 l -89.31,-105.29 0.03,-0.08"
                        />
                        <path
                          id="path136"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="M 1068.48,2029.89 964.746,2003.3 c 2.086,-12.61 4.859,-25.44 8.352,-38.32 l 104.552,26.8 102.55,120.94 c -4.75,10.8 -9.77,21.26 -15,31.32 l -96.74,-114.07 0.02,-0.08"
                        />
                        <path
                          id="path138"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1089.77,1972.63 -110.485,-28.32 c 4.082,-12.41 8.797,-24.71 14.156,-36.82 l 105.479,27.05 102.74,121.13 c -3.71,11.75 -7.77,23.34 -12.17,34.66 l -99.74,-117.62 0.02,-0.08"
                        />
                        <path
                          id="path140"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 939.84,2342.41 c -34.465,15.49 -70.485,29.98 -107.395,46.65 18.203,-25.5 33.653,-51.3 47.09,-77.52 l 43.59,11.17 16.715,19.7"
                        />
                        <path
                          id="path142"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 998.086,2313.8 c -12.367,6.76 -25.016,13.13 -37.918,19.24 l -24.938,-29.4 0.008,-0.1 -45.992,-11.78 c 5.094,-10.87 9.949,-21.81 14.406,-32.85 l 61.192,15.67 33.242,39.22"
                        />
                        <path
                          id="path144"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1153.6,1800.88 -63.88,-16.39 c 17.57,-12.12 36.89,-21.62 57.99,-27.57 12.58,11.5 38.42,27.46 47.13,43.19 14.3,24.09 22.84,51.62 26.73,80.98 l -67.99,-80.13 0.02,-0.08"
                        />
                        <path
                          id="path146"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1004.65,2201.64 -75.13,-19.25 c 3.523,-12.51 6.793,-25.14 9.796,-37.94 l 74.494,19.09 73.4,86.56 c -7.77,7.36 -15.74,14.31 -23.89,20.84 l -58.69,-69.22 0.02,-0.08"
                        />
                        <path
                          id="path148"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1025.93,2144.38 -81.891,-20.98 c 2.738,-12.68 5.274,-25.51 7.637,-38.5 l 83.414,21.38 88.18,103.99 c -6.74,8.68 -13.69,16.77 -20.84,24.44 l -76.53,-90.23 0.03,-0.1"
                        />
                        <path
                          id="path150"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 976.938,2255.52 0.019,-0.11 -65.547,-16.78 c 4.305,-11.74 8.27,-23.59 11.973,-35.54 l 69.152,17.71 53.545,63.15 c -9.44,6.71 -19.1,12.99 -28.97,18.91 l -40.172,-47.34"
                        />
                        <path
                          id="path152"
                          style={{
                            fill: "#c2ba17",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1047.21,2087.13 -91.776,-23.53 c 2.14,-12.78 4.16,-25.75 6.093,-38.86 l 94.833,24.3 97.75,115.25 c -5.42,9.29 -10.96,18.16 -16.61,26.44 -0.39,0.56 -0.77,1.07 -1.15,1.62 l -89.16,-105.12 0.02,-0.1"
                        />
                        <path
                          id="path154"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1068.34,1801.27 c 6.8,-6.04 13.94,-11.65 21.38,-16.78 l 63.88,16.39 -0.02,0.08 67.99,80.13 c 1.56,11.84 2.36,23.99 2.49,36.28 l -82.57,-97.34 -73.15,-18.76"
                        />
                        <path
                          id="path156"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1023.78,1852.56 c 4.37,-6.43 8.95,-12.7 13.76,-18.73 l 94.79,24.29 -0.03,0.08 89.31,105.29 c -1.07,9.47 -2.47,18.94 -4.17,28.42 l -97.23,-114.63 -96.43,-24.72"
                        />
                        <path
                          id="path158"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 993.441,1907.49 c 2.981,-6.7 6.129,-13.3 9.489,-19.82 l 108.12,27.72 -0.02,0.07 97.81,115.33 c -2.2,8.35 -4.59,16.65 -7.18,24.88 l -102.74,-121.13 -105.479,-27.05"
                        />
                        <path
                          id="path160"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 973.098,1964.98 c 1.859,-6.87 3.933,-13.8 6.187,-20.67 l 110.485,28.32 -0.02,0.08 99.74,117.62 c -2.96,7.6 -6.07,15.05 -9.29,22.39 l -102.55,-120.94 -104.552,-26.8"
                        />
                        <path
                          id="path162"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 961.527,2024.74 c 0.461,-3.15 0.946,-6.24 1.407,-9.4 0.523,-3.98 1.152,-8.02 1.812,-12.04 l 103.734,26.59 -0.02,0.08 96.74,114.07 c -3.61,6.96 -7.31,13.7 -11.09,20.25 l -97.75,-115.25 -94.833,-24.3"
                        />
                        <path
                          id="path164"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 951.676,2084.9 c 1.289,-7.04 2.554,-14.15 3.758,-21.3 l 91.776,23.53 -0.02,0.1 89.16,105.12 c -4.27,6.22 -8.63,12.2 -13.08,17.92 l -88.18,-103.99 -83.414,-21.38"
                        />
                        <path
                          id="path166"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 939.316,2144.45 c 1.645,-6.96 3.219,-13.99 4.723,-21.05 l 81.891,20.98 -0.03,0.1 76.53,90.23 c -4.98,5.35 -10.05,10.48 -15.22,15.39 l -73.4,-86.56 -74.494,-19.09"
                        />
                        <path
                          id="path168"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 923.383,2203.09 c 2.125,-6.86 4.183,-13.77 6.137,-20.7 l 75.13,19.25 -0.02,0.08 58.69,69.22 c -5.67,4.51 -11.41,8.85 -17.24,13.01 l -53.545,-63.15 -69.152,-17.71"
                        />
                        <path
                          id="path170"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 976.938,2255.52 40.172,47.34 c -6.27,3.78 -12.6,7.41 -19.024,10.94 l -33.242,-39.22 -61.192,-15.67 c 2.711,-6.73 5.27,-13.5 7.758,-20.28 l 65.547,16.78 -0.019,0.11"
                        />
                        <path
                          id="path172"
                          style={{
                            fill: "#aa9f15",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 935.23,2303.64 24.938,29.4 c -6.715,3.18 -13.488,6.32 -20.328,9.37 l -16.715,-19.7 -43.59,-11.17 c 3.356,-6.57 6.602,-13.17 9.711,-19.78 l 45.992,11.78 -0.008,0.1"
                        />
                        <path
                          id="path174"
                          style={{
                            fill: "#783f26",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1322.36,1834.54 c 0.44,-3.42 0.73,-6.89 0.73,-10.43 l -57.9,-281.25 c 0,-45.67 -37.04,-82.71 -82.72,-82.71 h -140.62 c -17.82,0 -34.28,5.69 -47.787,15.28 5.14,-40.76 39.847,-72.3 81.987,-72.3 h 140.62 c 45.69,0 82.73,37.04 82.73,82.73 l 57.9,281.23 c 0,27.87 -13.82,52.46 -34.94,67.45"
                        />
                        <path
                          id="path176"
                          style={{
                            fill: "#8c5234",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1182.47,1460.15 c 45.68,0 82.72,37.04 82.72,82.71 l 57.9,281.25 c 0,3.54 -0.29,7.01 -0.73,10.43 -13.5,9.58 -29.96,15.28 -47.78,15.28 h -256.43 c -45.685,0 -82.72,-37.04 -82.72,-82.73 l 57.906,-281.23 c 0,-3.55 0.293,-7.01 0.727,-10.43 13.507,-9.59 29.967,-15.28 47.787,-15.28 h 140.62"
                        />
                        <g transform="scale(10)" id="g178">
                          <text
                            id="text182"
                            style={{
                              fontVariant: "normal",
                              fontWeight: "normal",
                              fontSize: "44.55199814px",
                              fontFamily: "Bebas",
                              InkscapeFontSpecification: "Bebas",
                              writingMode: "lr-tb",
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            transform="matrix(1,0,0,-1,289.234,281.761)"
                          >
                            <tspan
                              id="tspan180"
                              y={0}
                              x="0 23.762701 47.967812 73.408211 91.443436 117.16904 140.23544"
                            >
                              CONTACT
                            </tspan>
                          </text>
                          <text
                            id="text186"
                            style={{
                              fontVariant: "normal",
                              fontWeight: "normal",
                              fontSize: "55.89780045px",
                              fontFamily: "Bebas",
                              InkscapeFontSpecification: "Bebas",
                              writingMode: "lr-tb",
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            transform="matrix(1,0,0,-1,334.4379,221.4059)"
                          >
                            <tspan
                              id="tspan184"
                              y={0}
                              x="0 29.972713 59.946289"
                            >
                              US!
                            </tspan>
                          </text>
                        </g>
                        <path
                          id="path188"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3267.12,3781.74 c -137.24,0 -248.5,-77.87 -248.5,-173.94 0,-46.65 26.4,-88.93 69.1,-120.16 l -69.1,-150.11 162.87,107.15 c 26.72,-6.87 55.48,-10.82 85.63,-10.82 137.23,0 248.49,77.88 248.49,173.94 0,96.07 -111.26,173.94 -248.49,173.94"
                        />
                        <path
                          id="path190"
                          style={{
                            fill: "#0a3470",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3195.35,3614.65 c 0,-13.68 -11.09,-24.76 -24.74,-24.76 -13.69,0 -24.77,11.08 -24.77,24.76 0,13.68 11.08,24.75 24.77,24.75 13.65,0 24.74,-11.07 24.74,-24.75"
                        />
                        <path
                          id="path192"
                          style={{
                            fill: "#0a3470",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3291.86,3614.65 c 0,-13.68 -11.08,-24.76 -24.74,-24.76 -13.67,0 -24.76,11.08 -24.76,24.76 0,13.68 11.09,24.75 24.76,24.75 13.66,0 24.74,-11.07 24.74,-24.75"
                        />
                        <path
                          id="path194"
                          style={{
                            fill: "#0a3470",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3388.39,3614.65 c 0,-13.68 -11.09,-24.76 -24.75,-24.76 -13.67,0 -24.76,11.08 -24.76,24.76 0,13.68 11.09,24.75 24.76,24.75 13.66,0 24.75,-11.07 24.75,-24.75"
                        />
                        {/* <path id="path196" style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} d="m 1102.59,2966.99 c -238.985,0 -433.41,194.43 -433.41,433.41 0,123.8 53.133,241.98 145.785,324.23 6.164,5.49 15.601,4.91 21.094,-1.25 5.468,-6.17 4.91,-15.62 -1.254,-21.09 -86.274,-76.59 -135.75,-186.62 -135.75,-301.89 0,-222.5 181.023,-403.53 403.535,-403.53 222.5,0 403.53,181.03 403.53,403.53 0,222.51 -181.03,403.54 -403.53,403.54 -8.25,0 -14.94,6.68 -14.94,14.93 0,8.25 6.69,14.94 14.94,14.94 238.98,0 433.41,-194.42 433.41,-433.41 0,-238.98 -194.43,-433.41 -433.41,-433.41" /> */}
                        {/* <path id="path198" style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} d="m 838.867,3499.92 c -7.773,0 -14.332,6.02 -14.887,13.89 l -12.808,181.72 -181.692,-35.81 c -8.167,-1.61 -15.949,3.67 -17.539,11.76 -1.597,8.1 3.668,15.95 11.766,17.54 l 198.289,39.09 c 4.172,0.85 8.555,-0.18 11.965,-2.78 3.402,-2.61 5.527,-6.55 5.824,-10.82 l 14,-198.6 c 0.574,-8.22 -5.625,-15.37 -13.851,-15.95 -0.36,-0.02 -0.715,-0.04 -1.067,-0.04" /> */}
                        {/* <g transform="scale(10)" id="g200">
                        <text id="text204" style={{ fontVariant: 'normal', fontWeight: 'normal', fontSize: '41.85419846px', fontFamily: 'Bebas', InkscapeFontSpecification: 'Bebas', writingMode: 'lr-tb', fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} transform="matrix(1,0,0,-1,87.6234,329.675)">
                          <tspan id="tspan202" y={0} x="0 18.5781">24</tspan>
                        </text>
                        <text id="text208" style={{ fontVariant: 'normal', fontWeight: 'normal', fontSize: '15.27659988px', fontFamily: 'Bebas', InkscapeFontSpecification: 'Bebas', writingMode: 'lr-tb', fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} transform="matrix(1,0,0,-1,87.622619,310.848)">
                          <tspan id="tspan206" y={0} x="0 8.3867207 16.675781 24.997066 32.939453">hours</tspan>
                        </text>
                      </g> */}
                        <path
                          id="path210"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 2986.92,4062.62 c -5.52,0 -10,4.47 -10,10 v 113.89 c 0,5.52 4.48,10 10,10 5.53,0 10,-4.48 10,-10 v -113.89 c 0,-5.53 -4.47,-10 -10,-10"
                        />
                        <path
                          id="path212"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3043.87,4119.57 h -113.89 c -5.53,0 -10,4.47 -10,10 0,5.52 4.47,10 10,10 h 113.89 c 5.52,0 10,-4.48 10,-10 0,-5.53 -4.48,-10 -10,-10"
                        />
                        <path
                          id="path214"
                          style={{
                            fill: "none",
                            stroke: "#ffffff",
                            strokeWidth: 20,
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 10,
                            strokeDasharray: "none",
                            strokeOpacity: 1,
                          }}
                          d="m 3209.03,4310.11 c 0,-34.9 -28.29,-63.19 -63.19,-63.19 -34.9,0 -63.19,28.29 -63.19,63.19 0,34.91 28.29,63.19 63.19,63.19 34.9,0 63.19,-28.28 63.19,-63.19 z"
                        />
                        <path
                          id="path216"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3273.96,4097.97 c 0,-17.45 -14.15,-31.6 -31.6,-31.6 -17.45,0 -31.59,14.15 -31.59,31.6 0,17.45 14.14,31.6 31.59,31.6 17.45,0 31.6,-14.15 31.6,-31.6"
                        />
                        <path
                          id="path218"
                          style={{
                            fill: "none",
                            stroke: "#ffffff",
                            strokeWidth: 20,
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 10,
                            strokeDasharray: "none",
                            strokeOpacity: 1,
                          }}
                          d="m 1536.69,3833.81 c 0,-34.9 -28.29,-63.19 -63.19,-63.19 -34.9,0 -63.2,28.29 -63.2,63.19 0,34.9 28.3,63.2 63.2,63.2 34.9,0 63.19,-28.3 63.19,-63.2 z"
                        />
                        <path
                          id="path220"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1714.92,3897.01 c 0,-17.45 -14.14,-31.6 -31.59,-31.6 -17.46,0 -31.61,14.15 -31.61,31.6 0,17.45 14.15,31.59 31.61,31.59 17.45,0 31.59,-14.14 31.59,-31.59"
                        />
                        <path
                          id="path222"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3840.46,1734.32 c -5.53,0 -10,4.47 -10,10 v 113.9 c 0,5.52 4.47,10 10,10 5.53,0 10,-4.48 10,-10 v -113.9 c 0,-5.53 -4.47,-10 -10,-10"
                        />
                        <path
                          id="path224"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3897.4,1791.27 h -113.88 c -5.53,0 -10,4.47 -10,10 0,5.54 4.47,10 10,10 h 113.88 c 5.53,0 10,-4.46 10,-10 0,-5.53 -4.47,-10 -10,-10"
                        />
                        <path
                          id="path226"
                          style={{
                            fill: "none",
                            stroke: "#ffffff",
                            strokeWidth: 20,
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 10,
                            strokeDasharray: "none",
                            strokeOpacity: 1,
                          }}
                          d="m 3642,1734.79 c 0,-34.9 -28.3,-63.19 -63.2,-63.19 -34.89,0 -63.19,28.29 -63.19,63.19 0,34.91 28.3,63.2 63.19,63.2 34.9,0 63.2,-28.29 63.2,-63.2 z"
                        />
                        <path
                          id="path228"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3789.59,1520.77 c 0,-17.45 -14.14,-31.6 -31.59,-31.6 -17.45,0 -31.6,14.15 -31.6,31.6 0,17.45 14.15,31.59 31.6,31.59 17.45,0 31.59,-14.14 31.59,-31.59"
                        />
                        <path
                          id="path230"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 3789.59,1963.49 c 0,-17.45 -14.14,-31.61 -31.59,-31.61 -17.45,0 -31.6,14.16 -31.6,31.61 0,17.45 14.15,31.6 31.6,31.6 17.45,0 31.59,-14.15 31.59,-31.6"
                        />
                        <path
                          id="path232"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 994.063,2708.38 c -5.524,0 -10,4.48 -10,10 v 113.89 c 0,5.53 4.476,10 10,10 5.523,0 9.997,-4.47 9.997,-10 v -113.89 c 0,-5.52 -4.474,-10 -9.997,-10"
                        />
                        <path
                          id="path234"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="M 1051.01,2765.33 H 937.113 c -5.519,0 -10,4.47 -10,10 0,5.52 4.481,10 10,10 h 113.897 c 5.52,0 10,-4.48 10,-10 0,-5.53 -4.48,-10 -10,-10"
                        />
                        <path
                          id="path236"
                          style={{
                            fill: "none",
                            stroke: "#ffffff",
                            strokeWidth: 20,
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 10,
                            strokeDasharray: "none",
                            strokeOpacity: 1,
                          }}
                          d="m 1357.98,2760.13 c 0,-34.91 -28.29,-63.2 -63.19,-63.2 -34.9,0 -63.2,28.29 -63.2,63.2 0,34.9 28.3,63.19 63.2,63.19 34.9,0 63.19,-28.29 63.19,-63.19 z"
                        />
                        <path
                          id="path238"
                          style={{
                            fill: "#ffffff",
                            fillOpacity: 1,
                            fillRule: "nonzero",
                            stroke: "none",
                          }}
                          d="m 1118.14,2590.26 c 0,-17.45 -14.14,-31.6 -31.59,-31.6 -17.45,0 -31.6,14.15 -31.6,31.6 0,17.45 14.15,31.6 31.6,31.6 17.45,0 31.59,-14.15 31.59,-31.6"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div
                  className="col-sm-12 col-md-6 col-lg-5 mt-5"
                  style={{ height: "25rem" }}
                >
                  <h1 className="h1 head">Contact Me</h1>

                  <p className="desc mt-3">
                    I am interested in freelance opportunities – especially
                    ambitious or large projects. However, if you have other
                    request or question, don’t hesitate to contact me using
                    below form either.
                  </p>
                  <form>
                    <div className="row">
                      <div className="col-sm-6 mt-3">
                        <input
                          autoComplete={"false"}
                          type="text"
                          className="form-control"
                          onChange={(e) => this.handleChange(e)}
                          name="name"
                          placeholder="Name"
                        />
                      </div>
                      <div className="col-sm-6 mt-3">
                        <input
                          autoComplete={"false"}
                          type="email"
                          className="form-control"
                          onChange={(e) => this.handleChange(e)}
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-sm-12 mt-3">
                        <input
                          autoComplete={"false"}
                          type="text"
                          className="form-control"
                          onChange={(e) => this.handleChange(e)}
                          name="subject"
                          placeholder="Subject"
                        />
                      </div>
                      <div className="col-sm-12 mt-3">
                        <textarea
                          autoComplete={"false"}
                          className="form-control"
                          onChange={(e) => this.handleChange(e)}
                          name="messege"
                          placeholder="Message"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="col-sm-12 mt-3">
                        <button
                          className="btn btn-block btn-outline-success"
                          type="button"
                          onClick={(e) => this.onSend()}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {enableAvatarView && !isMobileView ? (
                  <div
                    className="col-lg-7 col-md-12 col-sm-12 col-xs-12 desktop"
                    style={{ position: "absolute", top: "5%", right: 0 }}
                  >
                    <Scene
                      canvasProps={{
                        style: { height: "125vh" },
                      }}
                      avatarProps={{
                        clothName: AvatarWardrobe.FormalShirtTie,
                      }}
                    />
                    {/* <div className="request-loader">
                <img
                  className="img-fluid rounded-100 img-responsive mx-auto d-block"
                  src={"assets/images/my-image-min.jpg"}
                  alt="Ankur Sanghvi"
                />
              </div> */}
                  </div>
                ) : (
                  <div className="col-sm-12 col-md-4 col-lg-6 text-center desktop">
                    {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyCl4SUhqLQmotb4oKxm0umL1UE-nwN_hrk' }}
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                >
                  <AnyReactComponent
                    lat={this.props.center.lat}
                    lng={this.props.center.lng}
                    text="Here I'm"
                  />
                </GoogleMapReact> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 600 500"
                      id="contact-svg"
                      version="1.1"
                    >
                      <g
                        transform="matrix(1.3333333,0,0,-1.3333333,0,600)"
                        id="g10"
                      >
                        <g transform="scale(0.1)" id="g12">
                          {/* <path id="path16" style={{ fill: '#85a9dd', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} d="m 4292.05,2590.25 c 0,-998.9 -809.76,-1808.648 -1808.65,-1808.648 -998.89,0 -1808.654,809.748 -1808.654,1808.648 0,998.89 809.764,1808.66 1808.654,1808.66 998.89,0 1808.65,-809.77 1808.65,-1808.66" /> */}
                          <path
                            id="path18"
                            style={{
                              fill: "#e2e2e2",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3379.37,1306.33 c -823.56,0 -1647.12,0 -2470.686,0 -45.231,0 -71.871,28.78 -80.012,62.71 -11.695,-48.82 14.949,-108.32 80.012,-108.32 823.566,0 1647.126,0 2470.686,0 65.06,0 91.71,59.5 80.01,108.32 -8.13,-33.93 -34.78,-62.71 -80.01,-62.71"
                          />
                          <path
                            id="path20"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 908.684,1306.33 c 823.566,0 1647.126,0 2470.686,0 45.23,0 71.88,28.78 80.01,62.71 -8.13,33.95 -34.78,62.72 -80.01,62.72 -823.56,0 -1647.12,0 -2470.686,0 -45.231,0 -71.871,-28.77 -80.012,-62.72 8.141,-33.93 34.781,-62.71 80.012,-62.71"
                          />
                          <path
                            id="path22"
                            style={{
                              fill: "#000000",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2678.02,3131.54 c 243.25,45.61 83.61,577.7 -15.21,676.51 -114.01,106.42 -334.46,53.21 -478.86,83.62 -152.03,22.8 -334.47,91.21 -402.88,-129.23 -38,-129.23 45.61,-311.65 60.82,-440.87 15.2,-144.42 0,-243.24 174.82,-235.64 l 661.31,45.61"
                          />
                          <path
                            id="path24"
                            style={{
                              fill: "#eda18a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2195.53,2804.59 c 0,-51.93 0,-259.69 0,-311.64 h 222.61 c 0,51.95 0,259.71 0,311.64 h -222.61"
                          />
                          <path
                            id="path26"
                            style={{
                              fill: "#fdb498",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1923.54,3648.54 c -31.16,-311.76 -74.81,-848 336.71,-885.4 361.63,-37.43 461.41,280.58 448.94,573.64 0,81.07 -37.4,261.89 -62.36,342.93 l -723.29,-31.17"
                          />
                          <path
                            id="path28"
                            style={{
                              fill: "#000000",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2272.65,2505.47 c 124.56,-10.37 215.37,32.29 250.33,149.97 -35.2,4.98 -70.33,5.33 -104.84,0.76 -14.84,-140.99 -207.76,-92.76 -237.45,-3.7 -31.61,0.63 -62.04,-2.06 -91.5,-7.22 11.39,-95.44 94.94,-134.6 183.46,-139.81"
                          />
                          <path
                            id="path30"
                            style={{
                              fill: "var(--primary-color)",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2693.02,3470.1 c 238.8,82.98 270.93,-376.67 -5.19,-340.41 3.02,75.57 1.16,239.65 5.19,340.41"
                          />
                          <path
                            id="path32"
                            style={{
                              fill: "var(--primary-color)",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1850.82,3398.38 c 36.39,223.65 235.32,357.85 452.52,370.5 219.29,12.77 425.61,-114.01 446.73,-344.59 3.99,-43.56 72.43,-43.94 68.41,0 -52.34,571.42 -947.37,522.48 -1033.62,-7.72 -7.02,-43.15 58.89,-61.65 65.96,-18.19 v 0"
                          />
                          <path
                            id="path34"
                            style={{
                              fill: "var(--primary-color)",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1829.05,3177.78 c -2.03,-91.45 -46.03,-224.79 53.86,-278.95 87.45,-47.4 206.89,-27.15 301.89,-26.39 32.5,0.26 32.53,50.68 0,50.42 -83.57,-0.66 -169.02,-10.46 -251.03,10.36 -104.01,26.41 -56.03,166.28 -54.31,244.56 0.73,32.54 -49.7,32.46 -50.41,0 v 0"
                          />
                          <path
                            id="path36"
                            style={{
                              fill: "var(--primary-color)",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1912.06,3470.1 c -238.82,82.98 -270.93,-376.67 5.17,-340.41 -3.02,75.57 -1.16,239.65 -5.17,340.41"
                          />
                          <path
                            id="path38"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2522.98,2655.44 c -6.74,-22.69 -15.6,-42.53 -26.34,-59.8 h 195.19 c -54.93,31.43 -111.98,51.77 -168.85,59.8"
                          />
                          <path
                            id="path40"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1553.82,2048.35 h 1508.04 c -8.69,35.55 -18.76,69.78 -30.15,102.62 H 1590.98 c -13.86,-33.94 -26.2,-68.28 -37.16,-102.62"
                          />
                          <path
                            id="path42"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2089.19,2645.28 c -50.76,-8.91 -98.32,-25.98 -142.66,-49.64 h 157.2 c -7.26,14.53 -12.32,30.98 -14.54,49.64"
                          />
                          <path
                            id="path44"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1473.5,1501.06 h 1676.99 l -15.75,102.61 h -1651.1 l -10.14,-102.61"
                          />
                          <path
                            id="path46"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1626.74,2230.79 h 1373.47 c -16,36.22 -33.58,70.54 -52.64,102.6 H 1683.33 c -20.53,-32.92 -39.35,-67.35 -56.59,-102.6"
                          />
                          <path
                            id="path48"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1491.52,1683.49 h 1630.95 l -15.76,102.61 H 1501.67 l -10.15,-102.61"
                          />
                          <path
                            id="path50"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2272.65,2505.47 c -21.76,1.28 -43.19,4.64 -63.35,10.36 h -375.58 c -34.2,-30.53 -65.94,-65.01 -95.04,-102.62 h 1155.28 c -29.11,38.47 -60.44,72.7 -93.33,102.62 h -408.45 c -34.9,-10.85 -75.01,-14.07 -119.53,-10.36"
                          />
                          <path
                            id="path52"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1510.69,1877.47 -1.14,-11.55 h 1584.9 l -9.11,59.4 c -2.02,14.6 -4.31,28.99 -6.81,43.22 H 1530.79 c -7.89,-30.8 -14.59,-61.26 -20.1,-91.07"
                          />
                          <path
                            id="path54"
                            style={{
                              fill: "var(--primary-color)",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2496.64,2595.64 c -25.04,-40.28 -60.57,-66.16 -104.46,-79.81 h 408.45 c -34.76,31.64 -71.27,58.34 -108.8,79.81 h -195.19"
                          />
                          <path
                            id="path56"
                            style={{
                              fill: "var(--primary-color)",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2103.73,2595.64 h -157.2 c -40.29,-21.5 -77.84,-48.59 -112.81,-79.81 h 375.58 c -45.83,12.97 -84.88,38.32 -105.57,79.81"
                          />
                          <path
                            id="path58"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1683.33,2333.39 h 1264.24 c -16.84,28.3 -34.77,54.92 -53.61,79.82 H 1738.68 c -19.61,-25.35 -38.06,-52.07 -55.35,-79.82"
                          />
                          <path
                            id="path60"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1590.98,2150.97 h 1440.73 c -9.6,27.62 -20.17,54.19 -31.5,79.82 H 1626.74 c -12.81,-26.21 -24.77,-52.86 -35.76,-79.82"
                          />
                          <path
                            id="path62"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1530.79,1968.54 h 1547.74 c -4.74,27.3 -10.34,53.89 -16.67,79.81 H 1553.82 c -8.51,-26.7 -16.26,-53.39 -23.03,-79.81"
                          />
                          <path
                            id="path64"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1501.67,1786.1 h 1605.04 l -12.26,79.82 h -1584.9 l -7.88,-79.82"
                          />
                          <path
                            id="path66"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1483.64,1603.67 h 1651.1 l -12.27,79.82 H 1491.52 l -7.88,-79.82"
                          />
                          <path
                            id="path68"
                            style={{
                              fill: "var(--primary-color)",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1465.62,1421.24 h 1697.12 l -12.25,79.82 H 1473.5 l -7.88,-79.82"
                          />
                          <path
                            id="path70"
                            style={{
                              fill: "#0a3470",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2061.48,3239.74 c 23.3,72.68 113.93,69.13 149.41,8.05 5.54,-9.53 20.33,-0.92 14.77,8.63 -43.35,74.61 -153.03,74.12 -180.67,-12.13 -3.37,-10.54 13.13,-15.01 16.49,-4.55 v 0"
                          />
                          <path
                            id="path72"
                            style={{
                              fill: "#0a3470",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2430.13,3247.34 c 23.3,72.68 113.92,69.12 149.42,8.05 5.53,-9.53 20.31,-0.92 14.77,8.63 -43.35,74.6 -153.03,74.12 -180.67,-12.14 -3.38,-10.53 13.13,-15 16.48,-4.54 v 0"
                          />
                          <path
                            id="path74"
                            style={{
                              fill: "#e89a82",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2320.76,2901.6 c 90.29,3.52 184.92,75.79 206.52,164.58 5.22,21.43 -27.77,30.52 -32.97,9.1 -17.94,-73.68 -98.14,-136.53 -173.55,-139.47 -21.99,-0.86 -22.07,-35.08 0,-34.21 v 0"
                          />
                          <path
                            id="path76"
                            style={{
                              fill: "#000000",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1879.9,3640.83 c 159.62,-174.83 630.9,-205.24 843.72,-205.24 0,0 76.01,311.66 -349.65,334.46 -425.66,22.8 -494.07,-129.22 -494.07,-129.22"
                          />
                          <path
                            id="path78"
                            style={{
                              fill: "#e89a82",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2341.32,3257.9 28.3,-163.07 c 5.11,-29.43 -14.61,-57.44 -44.05,-62.55 -29.43,-5.11 -57.44,14.61 -62.54,44.05 -1.09,6.23 -1,12.61 0,18.5 l 28.29,163.07 c 2.4,13.81 15.54,23.06 29.34,20.66 10.83,-1.88 18.87,-10.42 20.66,-20.66"
                          />
                          <path
                            id="path80"
                            style={{
                              fill: "#8c85a9",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3019.22,2466.31 h -57.01 c 61.32,0 111.07,-40.48 111.07,-90.42 v -786.71 c 0,-49.95 -49.75,-90.44 -111.07,-90.44 h 57.01 c 61.34,0 111.06,40.49 111.06,90.44 v 786.71 c 0,49.94 -49.72,90.42 -111.06,90.42"
                          />
                          <path
                            id="path82"
                            style={{
                              fill: "#b6b4cc",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1500.22,1589.18 v 786.71 c 0,49.94 49.74,90.42 111.07,90.42 h -45.61 c -61.34,0 -111.07,-40.48 -111.07,-90.42 v -786.71 c 0,-49.95 49.73,-90.44 111.07,-90.44 h 45.61 c -61.33,0 -111.07,40.49 -111.07,90.44"
                          />
                          <path
                            id="path84"
                            style={{
                              fill: "#a4a0bc",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3073.28,1589.18 v 786.71 c 0,49.94 -49.75,90.42 -111.07,90.42 H 1611.29 c -61.33,0 -111.07,-40.48 -111.07,-90.42 v -786.71 c 0,-49.95 49.74,-90.44 111.07,-90.44 h 1350.92 c 61.32,0 111.07,40.49 111.07,90.44"
                          />
                          <path
                            id="path86"
                            style={{
                              fill: "#706b8e",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2093.24,1494.44 -89.12,3.19 119.71,473.51 c 9.49,62.24 67.65,105.03 129.9,95.52 l 67.62,-10.31 c 62.25,-9.48 105.03,-67.64 95.54,-129.89 l -1.1,-461.18 c -0.12,-0.77 -0.33,-1.5 -0.46,-2.27 l -322.09,31.43"
                          />
                          <path
                            id="path88"
                            style={{
                              fill: "#8c85a9",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2503.19,1501.06 c 0,-62.96 -51.06,-114.02 -114.02,-114.02 h -205.23 c -62.97,0 -114.02,51.06 -114.02,114.02 l 68.41,456.07 c 0,62.98 51.05,114.03 114.02,114.03 h 68.41 c 62.97,0 114.02,-51.05 114.02,-114.03 l 68.41,-456.07"
                          />
                          <path
                            id="path90"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1176.8,1919.05 -109.82,19.93 c 0.62,-13.62 2.06,-27.21 4.3,-40.59 l 97.95,-17.76 136.2,63.61 c 3.31,12.81 6.1,25.81 8.39,38.91 l -137.01,-63.99 -0.01,-0.11"
                          />
                          <path
                            id="path92"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1172.25,1858.15 -96.27,17.48 c 3.63,-14.64 8.41,-28.85 14.41,-42.44 l 74.29,-13.48 115.67,54.03 c 6.47,13.7 12.12,28.09 17,42.93 l -125.07,-58.44 -0.03,-0.08"
                          />
                          <path
                            id="path94"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1185.9,2040.89 -105.37,19.12 c -3.37,-12.32 -6.19,-25.14 -8.41,-38.3 l 106.21,-19.27 143.66,67.1 c 0.18,11.83 -0.01,23.42 -0.56,34.73 l -135.52,-63.31 -0.01,-0.07"
                          />
                          <path
                            id="path96"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1181.35,1979.98 -112.22,20.36 c -1.47,-12.96 -2.32,-26.14 -2.49,-39.38 l 107.14,-19.43 143.9,67.23 c 1.53,12.24 2.68,24.46 3.4,36.58 l -139.71,-65.28 -0.02,-0.08"
                          />
                          <path
                            id="path98"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1199.38,2378.59 c -24.84,28.46 -51.55,56.64 -78.14,87.19 5.9,-30.76 9.18,-60.64 10.46,-90.09 l 44.27,-8.03 23.41,10.93"
                          />
                          <path
                            id="path100"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1240.38,2328.27 c -8.42,11.33 -17.26,22.4 -26.43,33.31 l -34.95,-16.29 -0.02,-0.1 -46.71,8.48 c 0.09,-12 -0.06,-23.97 -0.62,-35.89 l 62.15,-11.28 46.58,21.77"
                          />
                          <path
                            id="path102"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1167.7,1797.24 -64.9,11.78 c 10.92,-18.35 24.52,-35.04 41.2,-49.26 16.22,5.21 46.37,8.94 60.85,19.59 23.05,15.92 42.29,37.4 58.08,62.45 l -95.21,-44.48 -0.02,-0.08"
                          />
                          <path
                            id="path104"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1199.55,2223.61 -76.32,13.86 c -2.02,-12.84 -4.31,-25.67 -6.92,-38.57 l 75.66,-13.72 102.82,48.03 c -3.97,9.96 -8.32,19.56 -13.02,28.89 l -82.21,-38.39 -0.01,-0.1"
                          />
                          <path
                            id="path106"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1194.99,2162.71 -83.16,15.09 c -2.81,-12.66 -5.86,-25.37 -9.14,-38.16 l 84.74,-15.38 123.52,57.7 c -2.51,10.68 -5.46,20.97 -8.74,30.91 L 1195,2162.8 l -0.01,-0.09"
                          />
                          <path
                            id="path108"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1196.83,2284.12 -0.01,-0.08 -66.57,12.09 c -1,-12.46 -2.34,-24.89 -3.96,-37.3 l 70.22,-12.75 75.02,35.05 c -5.77,10.05 -11.93,19.77 -18.41,29.3 l -56.29,-26.31"
                          />
                          <path
                            id="path110"
                            style={{
                              fill: "#917f17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1190.44,2101.79 -93.21,16.91 c -3.4,-12.5 -6.97,-25.15 -10.68,-37.87 l 96.32,-17.47 136.91,63.93 c -1.03,10.73 -2.37,21.1 -4.06,30.96 -0.11,0.7 -0.24,1.3 -0.36,1.97 l -124.9,-58.33 -0.02,-0.1"
                          />
                          <path
                            id="path112"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1090.39,1833.19 c 3.67,-8.32 7.79,-16.39 12.41,-24.17 l 64.9,-11.78 0.02,0.08 95.21,44.48 c 6.37,10.09 12.16,20.8 17.42,31.94 l -115.67,-54.03 -74.29,13.48"
                          />
                          <path
                            id="path114"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1071.28,1898.39 c 1.28,-7.67 2.84,-15.27 4.7,-22.76 l 96.27,-17.48 0.03,0.08 125.07,58.44 c 2.98,9.04 5.66,18.25 8.08,27.57 l -136.2,-63.61 -97.95,17.76"
                          />
                          <path
                            id="path116"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1066.64,1960.96 c -0.09,-7.32 0.01,-14.65 0.34,-21.98 l 109.82,-19.93 0.01,0.11 137.01,63.99 c 1.5,8.51 2.79,17.05 3.86,25.61 l -143.9,-67.23 -107.14,19.43"
                          />
                          <path
                            id="path118"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1072.12,2021.71 c -1.17,-7.03 -2.17,-14.17 -2.99,-21.37 l 112.22,-20.36 0.02,0.08 139.71,65.28 c 0.49,8.14 0.77,16.2 0.91,24.2 l -143.66,-67.1 -106.21,19.27"
                          />
                          <path
                            id="path120"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1086.55,2080.83 c -0.89,-3.04 -1.74,-6.06 -2.65,-9.11 -1.18,-3.85 -2.3,-7.77 -3.37,-11.71 l 105.37,-19.12 0.01,0.07 135.52,63.31 c -0.38,7.83 -0.94,15.52 -1.65,23.02 l -136.91,-63.93 -96.32,17.47"
                          />
                          <path
                            id="path122"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1102.69,2139.64 c -1.77,-6.96 -3.57,-13.93 -5.46,-20.94 l 93.21,-16.91 0.02,0.1 124.9,58.33 c -1.29,7.44 -2.76,14.68 -4.41,21.74 l -123.52,-57.7 -84.74,15.38"
                          />
                          <path
                            id="path124"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1116.31,2198.9 c -1.41,-7.03 -2.92,-14.07 -4.48,-21.1 l 83.16,-15.09 0.01,0.09 107.21,50.07 c -2.3,6.93 -4.77,13.71 -7.42,20.34 l -102.82,-48.03 -75.66,13.72"
                          />
                          <path
                            id="path126"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1126.29,2258.83 c -0.94,-7.11 -1.94,-14.24 -3.06,-21.36 l 76.32,-13.86 0.01,0.1 82.21,38.39 c -3.26,6.47 -6.68,12.81 -10.24,19.03 l -75.02,-35.05 -70.22,12.75"
                          />
                          <path
                            id="path128"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1196.83,2284.12 56.29,26.31 c -4.14,6.02 -8.37,11.97 -12.74,17.84 l -46.58,-21.77 -62.15,11.28 c -0.35,-7.21 -0.83,-14.45 -1.4,-21.65 l 66.57,-12.09 0.01,0.08"
                          />
                          <path
                            id="path130"
                            style={{
                              fill: "#776c0a",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1179,2345.29 34.95,16.29 c -4.78,5.71 -9.63,11.38 -14.57,17.01 l -23.41,-10.93 -44.27,8.03 c 0.32,-7.36 0.51,-14.69 0.57,-22.02 l 46.71,-8.48 0.02,0.1"
                          />
                          <path
                            id="path132"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1111.05,1915.39 -108.12,-27.72 c 6.26,-12.12 13.22,-23.9 20.85,-35.11 l 96.43,24.72 97.23,114.63 c -2.34,13.04 -5.21,26.01 -8.6,38.88 l -97.81,-115.33 0.02,-0.07"
                          />
                          <path
                            id="path134"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1132.33,1858.12 -94.79,-24.29 c 9.42,-11.78 19.68,-22.72 30.8,-32.56 l 73.15,18.76 82.57,97.34 c 0.17,15.17 -0.71,30.6 -2.45,46.12 l -89.31,-105.29 0.03,-0.08"
                          />
                          <path
                            id="path136"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="M 1068.48,2029.89 964.746,2003.3 c 2.086,-12.61 4.859,-25.44 8.352,-38.32 l 104.552,26.8 102.55,120.94 c -4.75,10.8 -9.77,21.26 -15,31.32 l -96.74,-114.07 0.02,-0.08"
                          />
                          <path
                            id="path138"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1089.77,1972.63 -110.485,-28.32 c 4.082,-12.41 8.797,-24.71 14.156,-36.82 l 105.479,27.05 102.74,121.13 c -3.71,11.75 -7.77,23.34 -12.17,34.66 l -99.74,-117.62 0.02,-0.08"
                          />
                          <path
                            id="path140"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 939.84,2342.41 c -34.465,15.49 -70.485,29.98 -107.395,46.65 18.203,-25.5 33.653,-51.3 47.09,-77.52 l 43.59,11.17 16.715,19.7"
                          />
                          <path
                            id="path142"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 998.086,2313.8 c -12.367,6.76 -25.016,13.13 -37.918,19.24 l -24.938,-29.4 0.008,-0.1 -45.992,-11.78 c 5.094,-10.87 9.949,-21.81 14.406,-32.85 l 61.192,15.67 33.242,39.22"
                          />
                          <path
                            id="path144"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1153.6,1800.88 -63.88,-16.39 c 17.57,-12.12 36.89,-21.62 57.99,-27.57 12.58,11.5 38.42,27.46 47.13,43.19 14.3,24.09 22.84,51.62 26.73,80.98 l -67.99,-80.13 0.02,-0.08"
                          />
                          <path
                            id="path146"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1004.65,2201.64 -75.13,-19.25 c 3.523,-12.51 6.793,-25.14 9.796,-37.94 l 74.494,19.09 73.4,86.56 c -7.77,7.36 -15.74,14.31 -23.89,20.84 l -58.69,-69.22 0.02,-0.08"
                          />
                          <path
                            id="path148"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1025.93,2144.38 -81.891,-20.98 c 2.738,-12.68 5.274,-25.51 7.637,-38.5 l 83.414,21.38 88.18,103.99 c -6.74,8.68 -13.69,16.77 -20.84,24.44 l -76.53,-90.23 0.03,-0.1"
                          />
                          <path
                            id="path150"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 976.938,2255.52 0.019,-0.11 -65.547,-16.78 c 4.305,-11.74 8.27,-23.59 11.973,-35.54 l 69.152,17.71 53.545,63.15 c -9.44,6.71 -19.1,12.99 -28.97,18.91 l -40.172,-47.34"
                          />
                          <path
                            id="path152"
                            style={{
                              fill: "#c2ba17",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1047.21,2087.13 -91.776,-23.53 c 2.14,-12.78 4.16,-25.75 6.093,-38.86 l 94.833,24.3 97.75,115.25 c -5.42,9.29 -10.96,18.16 -16.61,26.44 -0.39,0.56 -0.77,1.07 -1.15,1.62 l -89.16,-105.12 0.02,-0.1"
                          />
                          <path
                            id="path154"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1068.34,1801.27 c 6.8,-6.04 13.94,-11.65 21.38,-16.78 l 63.88,16.39 -0.02,0.08 67.99,80.13 c 1.56,11.84 2.36,23.99 2.49,36.28 l -82.57,-97.34 -73.15,-18.76"
                          />
                          <path
                            id="path156"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1023.78,1852.56 c 4.37,-6.43 8.95,-12.7 13.76,-18.73 l 94.79,24.29 -0.03,0.08 89.31,105.29 c -1.07,9.47 -2.47,18.94 -4.17,28.42 l -97.23,-114.63 -96.43,-24.72"
                          />
                          <path
                            id="path158"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 993.441,1907.49 c 2.981,-6.7 6.129,-13.3 9.489,-19.82 l 108.12,27.72 -0.02,0.07 97.81,115.33 c -2.2,8.35 -4.59,16.65 -7.18,24.88 l -102.74,-121.13 -105.479,-27.05"
                          />
                          <path
                            id="path160"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 973.098,1964.98 c 1.859,-6.87 3.933,-13.8 6.187,-20.67 l 110.485,28.32 -0.02,0.08 99.74,117.62 c -2.96,7.6 -6.07,15.05 -9.29,22.39 l -102.55,-120.94 -104.552,-26.8"
                          />
                          <path
                            id="path162"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 961.527,2024.74 c 0.461,-3.15 0.946,-6.24 1.407,-9.4 0.523,-3.98 1.152,-8.02 1.812,-12.04 l 103.734,26.59 -0.02,0.08 96.74,114.07 c -3.61,6.96 -7.31,13.7 -11.09,20.25 l -97.75,-115.25 -94.833,-24.3"
                          />
                          <path
                            id="path164"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 951.676,2084.9 c 1.289,-7.04 2.554,-14.15 3.758,-21.3 l 91.776,23.53 -0.02,0.1 89.16,105.12 c -4.27,6.22 -8.63,12.2 -13.08,17.92 l -88.18,-103.99 -83.414,-21.38"
                          />
                          <path
                            id="path166"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 939.316,2144.45 c 1.645,-6.96 3.219,-13.99 4.723,-21.05 l 81.891,20.98 -0.03,0.1 76.53,90.23 c -4.98,5.35 -10.05,10.48 -15.22,15.39 l -73.4,-86.56 -74.494,-19.09"
                          />
                          <path
                            id="path168"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 923.383,2203.09 c 2.125,-6.86 4.183,-13.77 6.137,-20.7 l 75.13,19.25 -0.02,0.08 58.69,69.22 c -5.67,4.51 -11.41,8.85 -17.24,13.01 l -53.545,-63.15 -69.152,-17.71"
                          />
                          <path
                            id="path170"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 976.938,2255.52 40.172,47.34 c -6.27,3.78 -12.6,7.41 -19.024,10.94 l -33.242,-39.22 -61.192,-15.67 c 2.711,-6.73 5.27,-13.5 7.758,-20.28 l 65.547,16.78 -0.019,0.11"
                          />
                          <path
                            id="path172"
                            style={{
                              fill: "#aa9f15",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 935.23,2303.64 24.938,29.4 c -6.715,3.18 -13.488,6.32 -20.328,9.37 l -16.715,-19.7 -43.59,-11.17 c 3.356,-6.57 6.602,-13.17 9.711,-19.78 l 45.992,11.78 -0.008,0.1"
                          />
                          <path
                            id="path174"
                            style={{
                              fill: "#783f26",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1322.36,1834.54 c 0.44,-3.42 0.73,-6.89 0.73,-10.43 l -57.9,-281.25 c 0,-45.67 -37.04,-82.71 -82.72,-82.71 h -140.62 c -17.82,0 -34.28,5.69 -47.787,15.28 5.14,-40.76 39.847,-72.3 81.987,-72.3 h 140.62 c 45.69,0 82.73,37.04 82.73,82.73 l 57.9,281.23 c 0,27.87 -13.82,52.46 -34.94,67.45"
                          />
                          <path
                            id="path176"
                            style={{
                              fill: "#8c5234",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1182.47,1460.15 c 45.68,0 82.72,37.04 82.72,82.71 l 57.9,281.25 c 0,3.54 -0.29,7.01 -0.73,10.43 -13.5,9.58 -29.96,15.28 -47.78,15.28 h -256.43 c -45.685,0 -82.72,-37.04 -82.72,-82.73 l 57.906,-281.23 c 0,-3.55 0.293,-7.01 0.727,-10.43 13.507,-9.59 29.967,-15.28 47.787,-15.28 h 140.62"
                          />
                          <g transform="scale(10)" id="g178">
                            <text
                              id="text182"
                              style={{
                                fontVariant: "normal",
                                fontWeight: "normal",
                                fontSize: "44.55199814px",
                                fontFamily: "Bebas",
                                InkscapeFontSpecification: "Bebas",
                                writingMode: "lr-tb",
                                fill: "#ffffff",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                              }}
                              transform="matrix(1,0,0,-1,289.234,281.761)"
                            >
                              <tspan
                                id="tspan180"
                                y={0}
                                x="0 23.762701 47.967812 73.408211 91.443436 117.16904 140.23544"
                              >
                                CONTACT
                              </tspan>
                            </text>
                            <text
                              id="text186"
                              style={{
                                fontVariant: "normal",
                                fontWeight: "normal",
                                fontSize: "55.89780045px",
                                fontFamily: "Bebas",
                                InkscapeFontSpecification: "Bebas",
                                writingMode: "lr-tb",
                                fill: "#ffffff",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                              }}
                              transform="matrix(1,0,0,-1,334.4379,221.4059)"
                            >
                              <tspan
                                id="tspan184"
                                y={0}
                                x="0 29.972713 59.946289"
                              >
                                US!
                              </tspan>
                            </text>
                          </g>
                          <path
                            id="path188"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3267.12,3781.74 c -137.24,0 -248.5,-77.87 -248.5,-173.94 0,-46.65 26.4,-88.93 69.1,-120.16 l -69.1,-150.11 162.87,107.15 c 26.72,-6.87 55.48,-10.82 85.63,-10.82 137.23,0 248.49,77.88 248.49,173.94 0,96.07 -111.26,173.94 -248.49,173.94"
                          />
                          <path
                            id="path190"
                            style={{
                              fill: "#0a3470",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3195.35,3614.65 c 0,-13.68 -11.09,-24.76 -24.74,-24.76 -13.69,0 -24.77,11.08 -24.77,24.76 0,13.68 11.08,24.75 24.77,24.75 13.65,0 24.74,-11.07 24.74,-24.75"
                          />
                          <path
                            id="path192"
                            style={{
                              fill: "#0a3470",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3291.86,3614.65 c 0,-13.68 -11.08,-24.76 -24.74,-24.76 -13.67,0 -24.76,11.08 -24.76,24.76 0,13.68 11.09,24.75 24.76,24.75 13.66,0 24.74,-11.07 24.74,-24.75"
                          />
                          <path
                            id="path194"
                            style={{
                              fill: "#0a3470",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3388.39,3614.65 c 0,-13.68 -11.09,-24.76 -24.75,-24.76 -13.67,0 -24.76,11.08 -24.76,24.76 0,13.68 11.09,24.75 24.76,24.75 13.66,0 24.75,-11.07 24.75,-24.75"
                          />
                          {/* <path id="path196" style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} d="m 1102.59,2966.99 c -238.985,0 -433.41,194.43 -433.41,433.41 0,123.8 53.133,241.98 145.785,324.23 6.164,5.49 15.601,4.91 21.094,-1.25 5.468,-6.17 4.91,-15.62 -1.254,-21.09 -86.274,-76.59 -135.75,-186.62 -135.75,-301.89 0,-222.5 181.023,-403.53 403.535,-403.53 222.5,0 403.53,181.03 403.53,403.53 0,222.51 -181.03,403.54 -403.53,403.54 -8.25,0 -14.94,6.68 -14.94,14.93 0,8.25 6.69,14.94 14.94,14.94 238.98,0 433.41,-194.42 433.41,-433.41 0,-238.98 -194.43,-433.41 -433.41,-433.41" /> */}
                          {/* <path id="path198" style={{ fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} d="m 838.867,3499.92 c -7.773,0 -14.332,6.02 -14.887,13.89 l -12.808,181.72 -181.692,-35.81 c -8.167,-1.61 -15.949,3.67 -17.539,11.76 -1.597,8.1 3.668,15.95 11.766,17.54 l 198.289,39.09 c 4.172,0.85 8.555,-0.18 11.965,-2.78 3.402,-2.61 5.527,-6.55 5.824,-10.82 l 14,-198.6 c 0.574,-8.22 -5.625,-15.37 -13.851,-15.95 -0.36,-0.02 -0.715,-0.04 -1.067,-0.04" /> */}
                          {/* <g transform="scale(10)" id="g200">
                        <text id="text204" style={{ fontVariant: 'normal', fontWeight: 'normal', fontSize: '41.85419846px', fontFamily: 'Bebas', InkscapeFontSpecification: 'Bebas', writingMode: 'lr-tb', fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} transform="matrix(1,0,0,-1,87.6234,329.675)">
                          <tspan id="tspan202" y={0} x="0 18.5781">24</tspan>
                        </text>
                        <text id="text208" style={{ fontVariant: 'normal', fontWeight: 'normal', fontSize: '15.27659988px', fontFamily: 'Bebas', InkscapeFontSpecification: 'Bebas', writingMode: 'lr-tb', fill: '#ffffff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }} transform="matrix(1,0,0,-1,87.622619,310.848)">
                          <tspan id="tspan206" y={0} x="0 8.3867207 16.675781 24.997066 32.939453">hours</tspan>
                        </text>
                      </g> */}
                          <path
                            id="path210"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 2986.92,4062.62 c -5.52,0 -10,4.47 -10,10 v 113.89 c 0,5.52 4.48,10 10,10 5.53,0 10,-4.48 10,-10 v -113.89 c 0,-5.53 -4.47,-10 -10,-10"
                          />
                          <path
                            id="path212"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3043.87,4119.57 h -113.89 c -5.53,0 -10,4.47 -10,10 0,5.52 4.47,10 10,10 h 113.89 c 5.52,0 10,-4.48 10,-10 0,-5.53 -4.48,-10 -10,-10"
                          />
                          <path
                            id="path214"
                            style={{
                              fill: "none",
                              stroke: "#ffffff",
                              strokeWidth: 20,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            d="m 3209.03,4310.11 c 0,-34.9 -28.29,-63.19 -63.19,-63.19 -34.9,0 -63.19,28.29 -63.19,63.19 0,34.91 28.29,63.19 63.19,63.19 34.9,0 63.19,-28.28 63.19,-63.19 z"
                          />
                          <path
                            id="path216"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3273.96,4097.97 c 0,-17.45 -14.15,-31.6 -31.6,-31.6 -17.45,0 -31.59,14.15 -31.59,31.6 0,17.45 14.14,31.6 31.59,31.6 17.45,0 31.6,-14.15 31.6,-31.6"
                          />
                          <path
                            id="path218"
                            style={{
                              fill: "none",
                              stroke: "#ffffff",
                              strokeWidth: 20,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            d="m 1536.69,3833.81 c 0,-34.9 -28.29,-63.19 -63.19,-63.19 -34.9,0 -63.2,28.29 -63.2,63.19 0,34.9 28.3,63.2 63.2,63.2 34.9,0 63.19,-28.3 63.19,-63.2 z"
                          />
                          <path
                            id="path220"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1714.92,3897.01 c 0,-17.45 -14.14,-31.6 -31.59,-31.6 -17.46,0 -31.61,14.15 -31.61,31.6 0,17.45 14.15,31.59 31.61,31.59 17.45,0 31.59,-14.14 31.59,-31.59"
                          />
                          <path
                            id="path222"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3840.46,1734.32 c -5.53,0 -10,4.47 -10,10 v 113.9 c 0,5.52 4.47,10 10,10 5.53,0 10,-4.48 10,-10 v -113.9 c 0,-5.53 -4.47,-10 -10,-10"
                          />
                          <path
                            id="path224"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3897.4,1791.27 h -113.88 c -5.53,0 -10,4.47 -10,10 0,5.54 4.47,10 10,10 h 113.88 c 5.53,0 10,-4.46 10,-10 0,-5.53 -4.47,-10 -10,-10"
                          />
                          <path
                            id="path226"
                            style={{
                              fill: "none",
                              stroke: "#ffffff",
                              strokeWidth: 20,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            d="m 3642,1734.79 c 0,-34.9 -28.3,-63.19 -63.2,-63.19 -34.89,0 -63.19,28.29 -63.19,63.19 0,34.91 28.3,63.2 63.19,63.2 34.9,0 63.2,-28.29 63.2,-63.2 z"
                          />
                          <path
                            id="path228"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3789.59,1520.77 c 0,-17.45 -14.14,-31.6 -31.59,-31.6 -17.45,0 -31.6,14.15 -31.6,31.6 0,17.45 14.15,31.59 31.6,31.59 17.45,0 31.59,-14.14 31.59,-31.59"
                          />
                          <path
                            id="path230"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 3789.59,1963.49 c 0,-17.45 -14.14,-31.61 -31.59,-31.61 -17.45,0 -31.6,14.16 -31.6,31.61 0,17.45 14.15,31.6 31.6,31.6 17.45,0 31.59,-14.15 31.59,-31.6"
                          />
                          <path
                            id="path232"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 994.063,2708.38 c -5.524,0 -10,4.48 -10,10 v 113.89 c 0,5.53 4.476,10 10,10 5.523,0 9.997,-4.47 9.997,-10 v -113.89 c 0,-5.52 -4.474,-10 -9.997,-10"
                          />
                          <path
                            id="path234"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="M 1051.01,2765.33 H 937.113 c -5.519,0 -10,4.47 -10,10 0,5.52 4.481,10 10,10 h 113.897 c 5.52,0 10,-4.48 10,-10 0,-5.53 -4.48,-10 -10,-10"
                          />
                          <path
                            id="path236"
                            style={{
                              fill: "none",
                              stroke: "#ffffff",
                              strokeWidth: 20,
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              strokeDasharray: "none",
                              strokeOpacity: 1,
                            }}
                            d="m 1357.98,2760.13 c 0,-34.91 -28.29,-63.2 -63.19,-63.2 -34.9,0 -63.2,28.29 -63.2,63.2 0,34.9 28.3,63.19 63.2,63.19 34.9,0 63.19,-28.29 63.19,-63.19 z"
                          />
                          <path
                            id="path238"
                            style={{
                              fill: "#ffffff",
                              fillOpacity: 1,
                              fillRule: "nonzero",
                              stroke: "none",
                            }}
                            d="m 1118.14,2590.26 c 0,-17.45 -14.14,-31.6 -31.59,-31.6 -17.45,0 -31.6,14.15 -31.6,31.6 0,17.45 14.15,31.6 31.6,31.6 17.45,0 31.59,-14.15 31.59,-31.6"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Contact;
