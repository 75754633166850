import React, { useEffect } from "react";
import "./loader.css";
import Scene from "../3D/Scene";
import { AvatarAnimetionsStack, AvatarWardrobe } from "../3D/Avatar";
import { enableAvatarView } from "../../config";
// import logo from '../../Assets/logo.png'

const Loader = (props) => {
  // const { isloading } = props;
  // console.log(isloading);

  useEffect(() => {
    return () => {
      if (!localStorage.getItem("isFirstLoad")) {
        console.log(localStorage.getItem("isFirstLoad"));
        localStorage.setItem("isFirstLoad", true);
      }
    };
  }, []);

  const isMobileView = window.innerWidth < 600;
  return (
    <main
      id="mainWrapper"
      className={`loader animated ${true ? "slideInLeft" : "slideOutRight"}`}
    >
      <div id="pageHead">
        <div className="page-header"></div>
      </div>
      <div
        id="pageBody"
        className={`d-flex justify-content-center align-items-center ${
          enableAvatarView && "w-100"
        }`}
      >
        {enableAvatarView ? (
          <>
            <p
              style={{
                position: "absolute",
                top: "10%",
                width: "100vw",
                textAlign: "center",
              }}
              className="head animated slower fadeInDown sildeInDown"
            >
              FETCHING INFORMATION
            </p>
            <div
              id="pageContent"
              style={{
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
                position: "absolute",
                top: "15%",
                width: "100vw",
              }}
            >
              <Scene
                canvasProps={{
                  style: { width: "100vw", height: "100vh" },
                }}
                avatarProps={{
                  animationType:
                    isMobileView || !localStorage.getItem("isFirstLoad")
                      ? AvatarAnimetionsStack.wave
                      : AvatarAnimetionsStack.point_back,
                  clothName: AvatarWardrobe.Formal,
                }}
              />
            </div>
          </>
        ) : (
          <div
            id="pageContent"
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className="loader-ripple ml-5">
              <div></div>
              <div></div>
            </div>
            <p className="head">FETCHING INFORMATION</p>
          </div>
        )}
      </div>
    </main>
  );
};

export default Loader;
